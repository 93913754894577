import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserData, updateTheme } from '../redux/slices/user';

const initialState = {
  themeMode: 'default',
  onChangeMode: () => {},
};

const ThemeModeContext = createContext(initialState);

ThemeModeProvider.propTypes = {
  children: PropTypes.node,
};

function ThemeModeProvider({ children }) {
  const dispatch = useDispatch();
  const { user } = useSelector(selectUserData);
  const stateThemeMode = user?.ui_mode || 'default';

  const themeMode =
    stateThemeMode === 'default'
      ? localStorage.getItem('theme') ||
        (window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light')
      : stateThemeMode;

  const onChangeMode = (value) => {
    if (value) {
      const actualTheme = localStorage.getItem('theme');
      if (actualTheme === value) return;
      localStorage.setItem('theme', value);
      const themeFormData = {
        theme: { ui_mode: value },
        headers: { 'Content-Type': 'application/json' },
      };
      dispatch(updateTheme(themeFormData));
    }
  };

  return (
    <ThemeModeContext.Provider
      value={{
        themeMode,
        onChangeMode,
      }}
    >
      {children}
    </ThemeModeContext.Provider>
  );
}

export { ThemeModeProvider, ThemeModeContext };

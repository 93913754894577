export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          background: 'transparent',
          backgroundOpacity: 0.5,
          borderRadius: 20,
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 29,
          paddingRight: 29,
          border: `1px solid ${theme.palette.cards.border}`,
          boxShadow: `0px 0px 10.4375px 3.13124px ${theme.palette.cards.shadows.primary}`,
          '&:hover': {
            boxShadow: `0px 0px 10.4375px 3.13124px ${theme.palette.cards.shadows.secondary}`,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-around',
          gap: 30,
          padding: 0,
          margin: 0,
        },
        spacing: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: 0,
        },
        spacing: {},
      },
    },
  };
}

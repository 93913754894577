import { makeRequest } from './client';

const writeChat = async (body) => {
  return await makeRequest(`writerly-chat/`, {
    method: 'POST',
    body: body,
  });
};

const getChatWritten = async ({ id }) => {
  return await makeRequest(`writerly-chat/${id}`, {
    method: 'GET',
  });
};

export { writeChat, getChatWritten };

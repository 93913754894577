export const pollRequest = async ({
  params,
  request,
  seconds = 1,
  code = 404000,
}) => {
  const { data, isSuccessful, statusKey } = await request({
    ...params,
  });

  if (!isSuccessful && data?.status === code) {
    await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
    return await pollRequest({
      params,
      request,
      seconds,
      code,
    });
  }
  return {
    pollData: data,
    pollStatusKey: statusKey,
    isPollSuccessful: isSuccessful,
  };
};

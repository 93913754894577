import { toggleMobileCreateOutputsOpen } from './navigation';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import i18next from 'i18next';

// @import Utilities
import { addCustomTemplate, addWords } from './user';
import { nomenclatureSnack } from '../../utils/nomenclature';
import { mapCategoryTemplates } from '../../utils';
import { sortFilter } from '../../utils/sortFilter';
import getRandomOption from '../../utils/getRandomOption';
import { formatCustomTemplate } from '../../utils/customTemplates';
import { pollRequest } from '../../utils/slices';
import { isFreePlan } from '../../utils/plan-codes';

// @import Services
import {
  createCustomTemplateRequest,
  getCustomTemplatesRequest,
  getTemplateHistory,
  getTemplatesRequest,
  getWriteData,
  write,
} from '../../api/create';

import {
  uploadCSVFile as uploadCSVFileAPI,
  batchUpload as batchUploadAPI,
  deleteCSVFile as deleteCSVFileAPI,
} from '../../api/csv';

// Data
import templateIdsByCategory from '../../data/templateIdsByCategory.json';

const historyAdapter = createEntityAdapter({});
export const initialState = {
  category: '',
  categories: {
    newDoc: {
      description: '',
      name: i18next.t('create.category.newDoc.name'),
      slug: '',
      icon: 'Outlined10',
      templates: [],
    },
    custom: {
      description: i18next.t('create.category.custom.description'),
      name: i18next.t('create.category.custom.name'),
      slug: 'custom',
      icon: 'writerlyCreateCustomTemplateIcon',
      templates: [],
    },
    advertising: {
      description: i18next.t('create.category.advertising.description'),
      name: i18next.t('create.category.advertising.name'),
      slug: 'advertising',
      icon: 'Outlined9',
      templates: [],
    },
    marketing: {
      description: i18next.t('create.category.marketing.description'),
      name: i18next.t('create.category.marketing.name'),
      slug: 'marketing',
      icon: 'Outlined42',
      templates: [],
    },
    sales: {
      description: i18next.t('create.category.sales.description'),
      name: i18next.t('create.category.sales.name'),
      slug: 'sales',
      icon: 'Outlined6',
      templates: [],
    },
    'e-commerce': {
      description: i18next.t('create.category.eCommerce.description'),
      name: i18next.t('create.category.eCommerce.name'),
      slug: 'e-commerce',
      icon: 'Outlined7',
      templates: [],
    },
    'social-media': {
      description: i18next.t('create.category.socialMedia.description'),
      name: i18next.t('create.category.socialMedia.name'),
      slug: 'social-media',
      icon: 'Outlined21',
      templates: [],
    },
    website: {
      description: i18next.t('create.category.website.description'),
      name: i18next.t('create.category.website.name'),
      slug: 'website',
      icon: 'Outlined40',
      templates: [],
    },
    academic: {
      description: i18next.t('create.category.academic.description'),
      name: i18next.t('create.category.academic.name'),
      slug: 'academic',
      icon: 'Outlined5',
      templates: [],
    },
    recruiting: {
      description: i18next.t('create.category.recruiting.description'),
      name: i18next.t('create.category.recruiting.name'),
      slug: 'recruiting',
      icon: 'Outlined43',
      templates: [],
    },
    'real-estate': {
      description: i18next.t('create.category.realEstate.description'),
      name: i18next.t('create.category.realEstate.name'),
      slug: 'real-estate',
      icon: 'Outlined22',
      templates: [],
    },
    'long-form': {
      description: i18next.t('create.category.longForm.description'),
      name: i18next.t('create.category.longForm.name'),
      slug: 'long-form',
      icon: 'Outlined10',
      templates: [],
    },
    editing: {
      description: i18next.t('create.category.editing.description'),
      name: i18next.t('create.category.editing.name'),
      slug: 'editing',
      icon: 'Outlined23',
      templates: [],
    },
    'see-all': {
      description: i18next.t('create.category.seeAll.description'),
      name: i18next.t('create.category.seeAll.name'),
      slug: 'see-all',
      icon: 'Outlined24',
      templates: [],
    },
  },
  subcategory: null,
  isCustomTemplateLoaded: false,
  loading: false,
  areTemplatesLoaded: false,
  historyLoading: false,
  answers: [],
  history: historyAdapter.getInitialState([]),
  historyCount: 0,
  bulkJob: {
    parsingCSV: false,
    loadingFile: false,
    loadingBatch: false,
    fileData: null,
  },
  waitingMessage: '',
};

export const writeRequest = createAsyncThunk(
  'create/writeRequest',
  async (params, thunkAPI) => {
    const intervalId = setInterval(() => {
      thunkAPI.dispatch(setWaitingMessage(getRandomOption()));
    }, 10000);

    const { data, isSuccessful, statusKey } = await write(params);

    if (!isSuccessful) {
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const state = thunkAPI.getState();
    const requestSeconds = isFreePlan(state?.user?.data?.plan_name) ? 1 : 3;

    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data.id },
      request: getWriteData,
      seconds: requestSeconds,
    });
    if (!isPollSuccessful) {
      return thunkAPI.rejectWithValue({
        ...pollData,
        statusKey: pollStatusKey,
      });
    }
    clearInterval(intervalId);
    thunkAPI.dispatch(setWaitingMessage(''));
    let counter = 0;
    pollData.server_response.forEach(
      (response) => (counter += response.text.split(' ').length)
    );
    thunkAPI.dispatch(addWords(counter));
    thunkAPI.dispatch(toggleMobileCreateOutputsOpen(true));
    return pollData;
  }
);
export const createCustomTemplate = createAsyncThunk(
  'create/createCustomTemplate',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await createCustomTemplateRequest(
      params
    );
    if (isSuccessful) {
      thunkAPI.dispatch(addCustomTemplate());
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getCustomTemplates = createAsyncThunk(
  'create/getCustomTemplates',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCustomTemplatesRequest();
    if (isSuccessful) {
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getHistory = createAsyncThunk(
  'create/getTemplateHistory',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getTemplateHistory(params);
    if (isSuccessful) {
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const uploadCSVFile = createAsyncThunk(
  'csv/uploadCSVFile',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await uploadCSVFileAPI(params);
    if (isSuccessful) {
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const deleteCSVFile = createAsyncThunk(
  'csv/deleteCSVFile',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await deleteCSVFileAPI(id);
    if (isSuccessful) {
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const batchUpload = createAsyncThunk(
  'csv/batchUpload',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await batchUploadAPI(params);
    if (isSuccessful) {
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getTemplates = createAsyncThunk(
  'create/getTemplates',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getTemplatesRequest();
    if (isSuccessful) {
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    .addCase(writeRequest.pending, (state) => {
      state.loading = true;
    })
    .addCase(writeRequest.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(writeRequest.fulfilled, (state, action) => {
      const data = action.payload;
      const formattedAnswers = data.server_response.map((item) => {
        const date = item.created_at;
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        return {
          id: item.id,
          label: state.subcategory.name,
          body: item?.translation?.text || item?.text,
          date: formattedDate,
        };
      });
      state.answers = formattedAnswers;
      state.loading = false;
    })

    .addCase(getCustomTemplates.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCustomTemplates.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getCustomTemplates.fulfilled, (state, action) => {
      const dbTemplates = action.payload;
      const newTemplates = dbTemplates.map((dbTemplate) =>
        formatCustomTemplate(dbTemplate)
      );
      const currentCategories = { ...state.categories };
      currentCategories.custom.templates = newTemplates.length
        ? newTemplates
        : [];
      state.loading = false;
      state.isCustomTemplateLoaded = true;
    })

    .addCase(createCustomTemplate.pending, (state) => {
      state.loading = true;
    })
    .addCase(createCustomTemplate.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(createCustomTemplate.fulfilled, (state, action) => {
      const dbTemplate = action.payload;
      const newTemplate = formatCustomTemplate(dbTemplate);
      const currentCategories = { ...state.categories };
      newTemplate && currentCategories.custom.templates.push(newTemplate);
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
        extraParams: {
          template: dbTemplate.name,
        },
      });
    })
    .addCase(getTemplates.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTemplates.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getTemplates.fulfilled, (state, action) => {
      const data = action.payload;
      const templatesByCategory = mapCategoryTemplates(
        templateIdsByCategory,
        data
      );
      const currentCategories = { ...state.categories };
      // Setting the templates
      Object.keys(currentCategories)
        .slice(2)
        .forEach((category) => {
          if (currentCategories[category]) {
            currentCategories[category].templates =
              templatesByCategory[category];
          }
        });
      // Setting the templates for see all
      const allTemplates = Object.values(currentCategories)
        .slice(1)
        .flatMap((cat) => {
          return cat.templates;
        })
        .filter((item) => !!item);

      currentCategories['see-all'].templates = allTemplates;
      state.areTemplatesLoaded = true;
      state.loading = false;
    })

    .addCase(getHistory.pending, (state) => {
      state.historyLoading = true;
    })
    .addCase(getHistory.rejected, (state, action) => {
      state.historyLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getHistory.fulfilled, (state, action) => {
      state.historyCount = action.payload.count;
      const data = action.payload.results;
      const formattedAnswers = data?.map((item) => {
        const date = item.created_at;
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        return {
          id: item.id,
          name: state.subcategory.name,
          label: state.subcategory.slug,
          body: item?.translation?.text || item?.text,
          date: formattedDate,
        };
      });
      if (formattedAnswers && Array.isArray(formattedAnswers)) {
        historyAdapter.upsertMany(state.history, formattedAnswers);
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
          extraParams: {
            subcategory: state.subcategory?.name,
          },
        });
      }
      state.historyLoading = false;
    })
    .addCase(uploadCSVFile.pending, (state) => {
      state.bulkJob.loadingFile = true;
    })
    .addCase(uploadCSVFile.rejected, (state, action) => {
      state.bulkJob.loadingFile = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(uploadCSVFile.fulfilled, (state, action) => {
      const data = {
        ...action.payload,
        name: action.payload?.file,
      };
      state.bulkJob.fileData = { ...data, meta: { ...data } };
      state.bulkJob.loadingFile = false;
    })
    .addCase(deleteCSVFile.pending, (state) => {
      state.bulkJob.loadingFile = true;
    })
    .addCase(deleteCSVFile.rejected, (state, action) => {
      state.bulkJob.loadingFile = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(deleteCSVFile.fulfilled, (state, action) => {
      state.bulkJob.fileData = action.payload.id;
      state.bulkJob.loadingFile = false;
    })
    .addCase(batchUpload.pending, (state) => {
      state.bulkJob.loadingBatch = true;
    })
    .addCase(batchUpload.rejected, (state) => {
      state.bulkJob.loadingBatch = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(batchUpload.fulfilled, (state) => {
      state.bulkJob.loadingBatch = false;
    });
};

export const create = createSlice({
  name: 'create',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSubcategory: (state, action) => {
      state.subcategory = action.payload;
      state.category = action.payload?.category;
      state.answers = [];
    },
    clearAnswers: (state) => {
      historyAdapter.upsertMany(state.history, state.answers);
      state.answers = [];
      state.historyCount = 0;
    },
    deleteAnswer: (state, action) => {
      const { id } = action.payload;

      const currentAnswers = [...state.answers].filter(
        (item) => item.id !== id
      );
      state.answers = currentAnswers;

      historyAdapter.removeOne(state.history, id);
    },
    setParsingCSV: (state, action) => {
      state.bulkJob.parsingCSV = action.payload;
    },
    setCSVfileData: (state, action) => {
      state.bulkJob.fileData = action.payload;
    },
    setWaitingMessage: (state, action) => {
      state.waitingMessage = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setCategory,
  setSubcategory,
  clearAnswers,
  deleteAnswer,
  setParsingCSV,
  setCSVfileData,
  setWaitingMessage,
} = create.actions;
export default create.reducer;

// ------------------SELECTORS-------------
export const selectLoading = (state) => state.create.loading;
export const selectHistoryLoading = (state) => state.create.historyLoading;
export const selectSubcategory = (state) => state.create.subcategory;
export const selectAnswers = (state) => state.create.answers;
export const selectHistoryCount = (state) => state.create.historyCount;
export const selectBatchLoader = (state) => state.create.bulkJob.loadingBatch;
export const selectFileLoader = (state) => state.create.bulkJob.loadingFile;
export const selectWaitingMessage = (state) => state.create.waitingMessage;
export const {
  selectAll: selectAllHistory,
  selectById: selectAnswerById,
  selectIds: selectAnswersIds,
} = historyAdapter.getSelectors((state) => state.create.history);

export const selectFilteredHistory = createSelector(
  [selectAllHistory, (state, label) => label],
  (historyAnswers = [], label = '') =>
    sortFilter({
      data: historyAnswers,
      filterCondition: (answer) => answer.label === label,
      sortValue: 'id',
      sortDirection: 'desc',
    })
);

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @import Reducers
import { getAllPersonas } from '../redux/slices/personas';
import { getAuthUserDetails, getTeamMembers } from '../redux/slices/user';
import { getAllDocuments } from '../redux/slices/drive';
import { getCustomTemplates, getTemplates } from '../redux/slices/create';

const initialState = {};

const UserContext = createContext(initialState);

UserProvider.propTypes = {
  children: PropTypes.node,
};

function UserProvider({ children }) {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user?.accessToken);
  const isOwner = useSelector((state) => state.user?.data?.is_account_owner);
  const token = localStorage.getItem('accessToken') || accessToken;
  const isEmailVerified = localStorage.getItem('emailVerified');

  useEffect(() => {
    if (token && isEmailVerified) {
      dispatch(getAllPersonas());
      dispatch(getAllDocuments());
      dispatch(getCustomTemplates());
      dispatch(getTemplates());
      dispatch(getAuthUserDetails({ token }));
    }
  }, [token]);

  useEffect(() => {
    if (token && isEmailVerified && isOwner) dispatch(getTeamMembers());
  }, [isOwner, token]);

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
}

export { UserProvider, UserContext };

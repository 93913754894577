import { makeRequest } from './client';

const getTeamMembersRequest = async () => {
  return await makeRequest(`team_member/members`);
};

const registerTeamMemberRequest = async (body) => {
  return await makeRequest(`team_member/register`, {
    method: 'POST',
    body,
  });
};

const addTeamMemberRequest = async (email) => {
  return await makeRequest('team_member/add', {
    method: 'POST',
    body: { email },
  });
};

const updateTeamMemberRequest = async ({
  id,
  role,
  teamMemberStatusActive,
}) => {
  return await makeRequest(`team_member/modify/${id}/`, {
    method: 'PATCH',
    body: { role, team_member_status_active: teamMemberStatusActive },
  });
};

const removeTeamMeberRequest = async (email) => {
  return await makeRequest('team_member/remove', {
    method: 'POST',
    body: { email },
  });
};

export {
  getTeamMembersRequest,
  registerTeamMemberRequest,
  addTeamMemberRequest,
  updateTeamMemberRequest,
  removeTeamMeberRequest,
};

// @import Config Files
import './translations/i18n';
import './utils/highlight';
import { router } from './routes/routes';

// @import Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// @import Utils
import reportWebVitals from './reportWebVitals';

// @import Components
import App from './App';
import { WriterlySnackBar } from './shared/SnackBar/SnackBar';
import './index.css';

// @import Providers
import { Provider } from 'react-redux';
import { UserProvider } from './contexts/UserContext';
import { SnackbarProvider } from 'notistack';
import { ThemeModeProvider } from './contexts/ThemeModeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProvider from './theme/theme';
import SentryProvider from './providers/sentry';
import TagManagerProvider from './providers/gtm';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SentryProvider dsn={process.env.REACT_APP_SENTRY_DSN}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID}
        >
          <TagManagerProvider gtmId={process.env.REACT_APP_GTM_ID}>
            <UserProvider>
              <ThemeModeProvider>
                <ThemeProvider>
                  <SnackbarProvider
                    preventDuplicate
                    Components={{
                      writerlySnackBar: WriterlySnackBar,
                    }}
                  >
                    <RouterProvider router={createBrowserRouter(router)}>
                      <App />
                    </RouterProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </ThemeModeProvider>
            </UserProvider>
          </TagManagerProvider>
        </GoogleOAuthProvider>
      </SentryProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// @import Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @import Custom Components
import { WriterlySpinner } from './Spinner';

// @import Mui Components
import { Typography, useTheme } from '@mui/material';

export const WriterlyBackgroundLoader = ({ title, visible, handleTimeOut }) => {
  const [timeExceeded, updateTimeExceeded] = useState(false);
  const [lastTimeJob, setLastTimeJob] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    newTimeOut();
  }, [visible]);

  const newTimeOut = () => {
    if (timeExceeded) updateTimeExceeded(false);
    const job = setTimeout(function () {
      if (handleTimeOut) {
        handleTimeOut();
      } else {
        updateTimeExceeded(true);
      }
    }, 20000); // If 20 seconds pass we hide the loader so as not to block the screen
    if (lastTimeJob) clearTimeout(lastTimeJob);
    setLastTimeJob(job);
  };

  return !timeExceeded && visible ? (
    <div
      className="flex items-center justify-center fixed w-full left-0 right-0 top-0 bottom-0 z-[9999]"
      style={{
        background: theme.palette.loader.spinner.background,
      }}
      data-testid={'backgroundLoader'}
    >
      <div className="text-center">
        <WriterlySpinner />
        <Typography variant="h7">{title}</Typography>
      </div>
    </div>
  ) : null;
};

WriterlyBackgroundLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  handleTimeOut: PropTypes.func,
};

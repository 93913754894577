// @import Dependencies
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// @import Reducers
import userReducer from './slices/user';
import personasReducer from './slices/personas';
import driveReducer from './slices/drive';
import navigationReducer from './slices/navigation';
import createReducer from './slices/create';
import authReducer from './slices/auth';
import chatterlyReducer from './slices/chatterly';

const combinedReducer = combineReducers({
  user: userReducer,
  personas: personasReducer,
  drive: driveReducer,
  navigation: navigationReducer,
  create: createReducer,
  auth: authReducer,
  chatterly: chatterlyReducer,
});

export default configureStore({
  reducer: combinedReducer,
});

import { makeRequest } from './client';

const batchUpload = async (body) => {
  return await makeRequest(`csv-batch-upload`, {
    method: 'POST',
    body,
  });
};

const uploadCSVFile = async (body, headers) => {
  return await makeRequest('csvfile/', {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteCSVFile = async (id) => {
  return await makeRequest(`csvfile/${id}`, {
    method: 'DELETE',
  });
};

export { batchUpload, uploadCSVFile, deleteCSVFile };

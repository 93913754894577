// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Services
import {
  changePasswordRequest,
  confirmEmail as confirmEmailAPI,
  forgotPassword as forgotPasswordAPI,
  google as googleAPI,
  login as loginAPI,
  logout as logoutAPI,
  register as registerAPI,
  setNewPassword as setNewPasswordAPI,
  verifyEmail as verifyEmailAPI,
} from '../../api/auth';
import {
  addTeamMemberRequest,
  updateTeamMemberRequest,
  registerTeamMemberRequest,
  removeTeamMeberRequest,
} from '../../api/teamMember';

// @import Reducers
import {
  getAuthUserDetails,
  removeTeamMemberFromState,
  updateTeamMemberRole,
} from '../../redux/slices/user';

// @import Utilities
import { nomenclatureSnack } from '../../utils/nomenclature';

// @import translation

export const initialState = {
  showForm: true,
  register: {
    loading: false,
    token: null,
  },
  registerTeamMember: {
    loading: false,
    token: null,
  },
  login: {
    loading: false,
  },
  logout: {
    loading: false,
  },
  forgotPassword: {
    email: false,
    error: false,
    loading: false,
  },
  setNewPassword: {
    error: true,
    loading: false,
  },
  verifyEmail: {
    email: null,
    loading: false,
    sentSinceRegister: false,
  },
};

export const register = createAsyncThunk(
  'auth/register',
  async (userData, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await registerAPI(userData);
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      return { ...userData, ...data, pendingVerify: true };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const sendVerifyEmail = createAsyncThunk(
  'auth/email-verification',
  async (emailData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await verifyEmailAPI();
    if (isSuccessful) return { ...emailData, ...data };
    else return thunkAPI.rejectWithValue({ ...emailData, data, statusKey });
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/verify-email',
  async (token, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await confirmEmailAPI(token);
    if (isSuccessful) return { ...data, statusKey };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await loginAPI(credentials);
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      thunkAPI.dispatch(getAuthUserDetails(data));
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  const { statusKey, data, isSuccessful } = await logoutAPI();
  if (isSuccessful) {
    return data;
  } else return thunkAPI.rejectWithValue({ ...data, statusKey });
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (forgotData, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await forgotPasswordAPI(
      forgotData
    );
    if (isSuccessful) return { ...forgotData, ...data };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async (passwordData, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await setNewPasswordAPI(
      passwordData,
      passwordData.token
    );
    if (isSuccessful) return data;
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (body, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await changePasswordRequest(body);
    if (isSuccessful) return { ...data, statusKey };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const google = createAsyncThunk(
  'auth/google',
  async ({ token, credential }, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await googleAPI({
      credential,
      organization_token: token,
    });
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      thunkAPI.dispatch(getAuthUserDetails(data));
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const registerTeamMember = createAsyncThunk(
  'auth/registerTeamMember',
  async (userData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await registerTeamMemberRequest(
      userData
    );
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      thunkAPI.dispatch(getAuthUserDetails({ ...data, is_first_time: false }));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const addTeamMember = createAsyncThunk(
  'auth/addTeamMember',
  async (email, thunkAPI) => {
    const { data, statusKey, isSuccessful } = await addTeamMemberRequest(email);
    if (isSuccessful) return { statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateTeamMember = createAsyncThunk(
  'auth/updateTeamMember',
  async ({ id, teamMemberStatusActive, role }, thunkAPI) => {
    const { statusKey, isSuccessful, data } = await updateTeamMemberRequest({
      id,
      role,
      teamMemberStatusActive,
    });
    if (isSuccessful) {
      thunkAPI.dispatch(updateTeamMemberRole(data));
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const removeTeamMember = createAsyncThunk(
  'auth/removeTeamMember',
  async (email, thunkAPI) => {
    const { data, statusKey, isSuccessful } = await removeTeamMeberRequest(
      email
    );
    if (isSuccessful) {
      thunkAPI.dispatch(removeTeamMemberFromState(email));
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    // Register
    .addCase(register.pending, (state) => {
      state.register.loading = true;
    })
    .addCase(register.fulfilled, (state, action) => {
      state.register = {
        ...state.register,
        token: action.payload.token,
        loading: !action.payload?.pendingVerify,
      };
      state.verifyEmail = {
        email: action.payload.email,
        sentSinceRegister: true,
        loading: false,
      };
    })
    .addCase(register.rejected, (state, action) => {
      state.register.loading = false;
      localStorage.clear();
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
        snackCustomConfig: {
          autoHideDuration: null,
          messageLinks: {
            'login here': '/login',
          },
        },
      });
    })
    // Send Verify Email
    .addCase(sendVerifyEmail.pending, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = true;
      state.verifyEmail.loading = true;
    })
    .addCase(sendVerifyEmail.fulfilled, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = false;

      state.verifyEmail = {
        ...state.verifyEmail,
        sentSinceRegister: validateOrigin(data?.origin, 'register'),
        loading: false,
      };
    })
    .addCase(sendVerifyEmail.rejected, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = false;
      state.verifyEmail.loading = false;
      localStorage.clear();
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Confirm Email
    .addCase(confirmEmail.pending, (state, action) => {
      state.verifyEmail.loading = true;
    })
    .addCase(confirmEmail.fulfilled, (state, action) => {
      state.verifyEmail.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
        extraParams: { email: '' },
      });
    })
    .addCase(confirmEmail.rejected, (state, action) => {
      state.verifyEmail.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Login
    .addCase(login.pending, (state, action) => {
      state.login.loading = true;
    })
    .addCase(login.fulfilled, (state, action) => {})
    .addCase(login.rejected, (state, action) => {
      state.login.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Logout
    .addCase(logout.pending, (state, action) => {
      state.logout.loading = true;
    })
    .addCase(logout.fulfilled, (state, action) => {
      localStorage.clear();
      window.location.reload();
    })
    .addCase(logout.rejected, (state, action) => {
      localStorage.clear();
      window.location.reload();
      state.logout.loading = false;
    })
    // Forgot password
    .addCase(forgotPassword.pending, (state, action) => {
      state.forgotPassword.loading = true;
    })
    .addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword = {
        loading: false,
        error: false,
        email: action.payload?.email,
      };
    })
    .addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = true;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Set New password
    .addCase(setNewPassword.pending, (state, action) => {
      state.setNewPassword.loading = true;
    })
    .addCase(setNewPassword.fulfilled, (state, action) => {
      state.setNewPassword = {
        loading: false,
        error: false,
      };
    })
    .addCase(setNewPassword.rejected, (state, action) => {
      state.setNewPassword = {
        loading: false,
        error: true,
      };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Change password
    .addCase(changePassword.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(changePassword.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    // Google
    .addCase(google.pending, (state, action) => {
      state.login.loading = true;
      state.register.loading = true;
    })
    .addCase(google.fulfilled, (state, action) => {})
    .addCase(google.rejected, (state, action) => {
      state.login.loading = false;
      state.register.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Register Team Member
    .addCase(registerTeamMember.pending, (state) => {
      state.registerTeamMember.loading = true;
    })
    .addCase(registerTeamMember.fulfilled, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(registerTeamMember.rejected, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Add Team Member
    .addCase(addTeamMember.pending, (state) => {})
    .addCase(addTeamMember.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(addTeamMember.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Update Team Member
    .addCase(updateTeamMember.pending, (state) => {})
    .addCase(updateTeamMember.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateTeamMember.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Remove Team Member
    .addCase(removeTeamMember.pending, (state) => {})
    .addCase(removeTeamMember.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(removeTeamMember.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    resetInit: (state, action) => {
      return initialState;
    },
    setShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    resetRegisterVerifyEmail: (state, action) => {
      state.verifyEmail.sentSinceRegister = action.payload;
    },
    setVerifyEmail: (state, action) => {
      state.verifyEmail = { ...state.verifyEmail, ...action.payload };
    },
    setLoginLoading: (state, action) => {
      state.login.loading = action.payload;
    },
  },
});

const validateOrigin = (origin, matchOrigin) => {
  return origin === matchOrigin;
};
// ------------------EXPORT REDUCERS-------------
export const {
  resetInit,
  setShowForm,
  resetRegisterVerifyEmail,
  setVerifyEmail,
  setLoginLoading,
} = authSlice.actions;
export default authSlice.reducer;

// ------------------SELECTORS-------------
export const selectAuth = (state) => state.auth;

export default function Slider(theme) {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          height: 3,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            height: 15,
            color: theme.palette.primary.main,
            width: 15,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            display: 'none',
          },
          '& .MuiSlider-markLabel': {
            fontSize: '16px',
            color: theme.palette.text.secondary,
            '&[style="left: 0%;"]': {
              transform: 'translateX(0%)',
            },
            '&[style="left: 100%;"]': {
              transform: 'translateX(-100%)',
            },
          },
        },
      },
    },
  };
}

import i18next from 'i18next';

function getRandomOption() {
  const options = [
    i18next.t('waitingMessage.1'),
    i18next.t('waitingMessage.2'),
    i18next.t('waitingMessage.3'),
    i18next.t('waitingMessage.4'),
    i18next.t('waitingMessage.5'),
  ];

  const randomIndex = Math.floor(Math.random() * options.length);

  return options[randomIndex];
}

export default getRandomOption;

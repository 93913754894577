// @import Dependencies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
const SentryProvider = ({ dsn, children }) => {
  useEffect(() => {
    const environment = process.env.REACT_APP_ENV;
    // Sentry Initialization
    if (environment !== 'develop' && dsn) {
      Sentry.init({
        dsn,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        environment,
        ignoreErrors: [
          /NotAllowedError/,
          /SyntaxError: webkit-masked-url:\/\/hidden\//,
          'AbortError',
          /AbortController is not defined/,
          /^SyntaxError: Unexpected token '<'/,
        ],
        tracesSampleRate: 1.0,
      });
    }
  }, [dsn]);

  return <>{children}</>;
};

SentryProvider.propTypes = {
  dsn: PropTypes.any,
  children: PropTypes.node,
};

export default SentryProvider;

// @import Utilities
import { snack } from './snackbar';

export const nomenclatureSnack = ({
  type,
  message,
  extraParams,
  snackCustomConfig,
  url,
}) => {
  const isDefault = message.split('.')[0] === 'default';
  return snack(
    type,
    `${isDefault ? 'default' : message}`,
    {
      ...extraParams,
      ns: 'nomenclature',
    },
    snackCustomConfig,
    url
  );
};

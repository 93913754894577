import { makeRequest } from './client';

const fetchAllPersonas = async () => {
  return await makeRequest('persona/');
};

const addStandardPersona = async (body) => {
  return await makeRequest(`persona-standard/`, {
    method: 'POST',
    body: body,
  });
};

const editStandardPersona = async (body) => {
  return await makeRequest(`persona-standard/${body.id}/`, {
    method: 'PATCH',
    body: body,
  });
};

const addCustomPersona = async (body) => {
  return await makeRequest(`persona-custom/`, {
    method: 'POST',
    body: body,
  });
};

const editCustomPersona = async (body) => {
  return await makeRequest(`persona-custom/${body.id}/`, {
    method: 'PATCH',
    body: body,
  });
};

const deletePersona = async (id) => {
  return await makeRequest(`persona/${id}`, { method: 'DELETE' });
};

const uploadFileRequest = async (body) => {
  return await makeRequest(`persona-filetraining/`, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteFileRequest = async (id) => {
  return await makeRequest(`persona-filetraining/${id}`, {
    method: 'DELETE',
  });
};

const getFilesByPersonaRequest = async (id) => {
  return await makeRequest(`persona-filetraining-by-persona/${id}`, {
    method: 'GET',
  });
};

export {
  uploadFileRequest,
  deleteFileRequest,
  addStandardPersona,
  editStandardPersona,
  fetchAllPersonas,
  deletePersona,
  addCustomPersona,
  editCustomPersona,
  getFilesByPersonaRequest,
};

import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { authPaths } from '../routes/paths';
import { useSelector } from 'react-redux';
import { selectUserData } from '../redux/slices/user';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { user } = useSelector(selectUserData);
  const location = useLocation().pathname;
  const isAuthenticated =
    localStorage.getItem('accessToken') &&
    localStorage.getItem('emailVerified');
  if (
    user.id &&
    user.is_first_time &&
    !location.includes('first-time') &&
    location !== '/signup-plan'
  ) {
    if (!user.job_type) return <Navigate to="/first-time-questionnaire" />;
    return <Navigate to="/first-time" />;
  }
  if (!isAuthenticated) {
    return <Navigate to={authPaths.root} />;
  }
  if (window?.tidioChatApi) window.tidioChatApi?.show();
  return <>{children}</>;
}

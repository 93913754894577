import { makeRequest } from './client';

const write = async (body) => {
  return await makeRequest(`writerly/`, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getWriteData = async ({ id }) => {
  return await makeRequest(`writerly/${id}`, {
    method: 'GET',
  });
};

const getTemplateHistory = async ({ id, page }) => {
  return await makeRequest(
    `prompt_templates/${id}/history/?page=${page || 1}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const createCustomTemplateRequest = async (body) => {
  return await makeRequest(`prompt-template/custom-template/`, {
    method: 'POST',
    body,
  });
};

const getCustomTemplatesRequest = async () => {
  return await makeRequest(`prompt-template/custom-template/`, {
    method: 'GET',
  });
};

const getTemplatesRequest = async () => {
  return await makeRequest('prompt_templates/', {
    method: 'GET',
  });
};

export {
  write,
  getWriteData,
  getTemplateHistory,
  getTemplatesRequest,
  createCustomTemplateRequest,
  getCustomTemplatesRequest,
};

export default function InputBase(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '::before': {
            borderBottom: `1px solid ${theme.palette.text.secondary}!important`,
          },
          '::after': {
            borderBottom: `1px solid ${theme.palette.text.primary}!important`,
          },
        },
        input: {},
        error: {
          color: theme.palette.various.errorPlaceholder,
          '::after': {
            borderBottom: `1px solid ${theme.palette.various.errorMessages}!important`,
          },
        },
      },
    },
  };
}

const ROLES = {
  cirrusAIBusinessCloudAnnual: 'AnnualCirrusAIBusinessCloud',
  cirrusAIBusinessCloudMonthly: 'MonthlyCirrusAIBusinessCloud',
  stratosAIBusinessCloudAnnual: 'AnnualStratosAIBusinessCloud',
  stratosAIBusinessCloudMonthly: 'MonthlyStratosAIBusinessCloud',
  nimbusAIBusinessCloudAnnual: 'AnnualNimbusAIBusinessCloud',
  nimbusAIBusinessCloudMonthly: 'MonthlyNimbusAIBusinessCloud',
  teamAIStudioAnnual: 'AnnualTeamAIStudio',
  teamAIStudioMonthly: 'MonthlyTeamAIStudio',
  creatorAIStudioAnnual: 'AnnualCreatorAIStudio',
  creatorAIStudioMonthly: 'MonthlyCreatorAIStudio',
  creatorAIChatAnnual: 'AnnualCreatorAIChat',
  creatorAIChatMonthly: 'MonthlyCreatorAIChat',
  creatorAIFree: 'CreatorAIFree',
};

const PERMISSIONS = {
  [ROLES.creatorAIFree]: {
    storageLimit: 0,
    hasChat: false,
    hasPersonas: false,
    hasCustomPersonas: false,
    hasCustomTemplates: false,
    hasShare: false,
  },
  [ROLES.creatorAIChatMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,
    hasShare: false,
  },
  [ROLES.creatorAIStudioMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,
    hasShare: false,
  },
  [ROLES.teamAIStudioMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,
    hasShare: true,
  },
  [ROLES.nimbusAIBusinessCloudMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,

    hasShare: true,
  },
  [ROLES.stratosAIBusinessCloudMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,
    hasShare: true,
  },
  [ROLES.cirrusAIBusinessCloudMonthly]: {
    storageLimit: false,
    hasChat: true,
    hasPersonas: true,
    hasCustomPersonas: true,
    hasCustomTemplates: true,
    hasShare: true,
  },
};

PERMISSIONS[ROLES.creatorAIChatAnnual] =
  PERMISSIONS[ROLES.creatorAIChatMonthly];
PERMISSIONS[ROLES.creatorAIStudioAnnual] =
  PERMISSIONS[ROLES.creatorAIStudioMonthly];
PERMISSIONS[ROLES.teamAIStudioAnnual] = PERMISSIONS[ROLES.teamAIStudioMonthly];
PERMISSIONS[ROLES.nimbusAIBusinessCloudAnnual] =
  PERMISSIONS[ROLES.nimbusAIBusinessCloudMonthly];
PERMISSIONS[ROLES.stratosAIBusinessCloudAnnual] =
  PERMISSIONS[ROLES.stratosAIBusinessCloudMonthly];
PERMISSIONS[ROLES.cirrusAIBusinessCloudAnnual] =
  PERMISSIONS[ROLES.cirrusAIBusinessCloudMonthly];

export { ROLES, PERMISSIONS };

import logo from '../../assets/images/writerly-chat-avatar.png';
import i18next from 'i18next';

export const USER_NAME = 'user';
export const ASSISTANCE_NAME = 'assistant';
export const ASSISTANCE_AVATAR = logo;
export const WELCOME_MESSAGE = i18next.t('chatterly.welcomeMessage');

export const DEFAULT_SETTINGS = {
  input_language: 'EN-US',
  output_language: 'EN-US',
  temperature: 1,
  max_tokens: 768,
  top_p: 1,
  n: 1,
  stream: false,
  stop: null,
  logit_bias: {},
  presence_penalty: 0,
  frequency_penalty: 0,
  tone: 'Neutral',
};

export const ACTION_BUTTONS = [
  i18next.t('chatterly.actions.aiActions.action1'),
  i18next.t('chatterly.actions.aiActions.action2'),
  i18next.t('chatterly.actions.aiActions.action3'),
];

// Import the polyfill for the URLSearchParams
// import './polyfills/urlSearchParams';

/**
 * Get data from the local storage and parse it to be used instantly.
 * @param {String} key The key to query in the local storage.
 */
export const getFromLocalStorage = (key) => {
  // Check if the method is being used by SSR and we don't have access to the window object.
  const hasWindow = !!window;
  const hasLocalStorage = !!window.localStorage;
  if (!hasWindow || !hasLocalStorage) return null;

  const localStorageItem = window.localStorage.getItem(key);
  // No worries with other falsy values, the only valid falsy value here is the empty string
  if (!localStorageItem) return null;

  // There could be a saved string which is not JSON parseable and we need to take
  // that into account.
  return JSON.parse(localStorageItem);
};

/**
 * Set the data to the local storage. We use JSON.stringify() to save the passed data as
 * a string and to be able to get its data again without any loss of information.
 * @param {String} key The key to save in the local storage.
 * @param {String} value The value that is going to be saved in the local storage.
 */
export const saveToLocalStorage = (key, value) => {
  const hasWindow = !!window;
  const hasLocalStorage = !!window.localStorage;
  if (!hasWindow || !hasLocalStorage)
    return { saved: false, reason: 'No access to the window object' };

  // Convert the passed value to a string
  // We may receive a cyclic object error or an error when trying to serialize a BigInt value
  let stringifiedData;
  try {
    stringifiedData = JSON.stringify(value);
  } catch (ex) {
    return { saved: false, reason: ex.message };
  }

  // Some browsers as Safari sets the size of the localStorage to be 0 bytes when the user access
  // any website using private mode.
  // Also, the users' browser may throw an exception if the browser has its storage full.
  try {
    window.localStorage.setItem(key, stringifiedData);
    return { saved: true };
  } catch (ex) {
    return { saved: false, reason: ex.message };
  }
};

/**
 * Remove an specific key from the localStorage
 * @param {String} key The key to save in the local storage.
 */
export const removeFromLocalStorage = (key) => {
  const hasWindow = !!window;
  const hasLocalStorage = !!window.localStorage;
  if (!hasWindow || !hasLocalStorage) return undefined;

  return window.localStorage.removeItem(key);
};

/**
 * From a passed object get the according query params.
 * This method also removes all params that are not defined.
 * @param {Object} params - The object with the parameters that is going to be converted into an string.
 * @param {Object} options = An object with all the options that are going to modify the behavior.
 * @param {Bool} options.clean - If true, it removes all the parameters that are not defined.
 */
export const getUrlStringParamsFromObject = (
  params = {},
  { clean = true } = {}
) => {
  if (typeof params !== 'object' || params == null) {
    throw new TypeError('The passed parameter should be an object');
  }
  let modifiedParams = params;

  if (clean) {
    modifiedParams = Object.keys(modifiedParams).reduce((prev, paramKey) => {
      if (modifiedParams[paramKey] == null || modifiedParams[paramKey] === '') {
        return prev;
      }
      return { ...prev, [paramKey]: modifiedParams[paramKey] };
    }, {});
  }

  return new URLSearchParams(modifiedParams).toString();
};

/**
 * Get the search parameters passed through the URL of the site.
 */
export const getUrlParams = () => {
  const url = window.location.href;
  const urlObject = new URL(url);
  return new URLSearchParams(urlObject.search);
};

/**
 * Return true if the element is a DOM Node and we can use the Browser APIs on it.
 * @param {Node} element The element to be checked if its an HTML DOM Element or not.
 * @returns {boolean} Is the element a valid HTMLDOMElement?
 */
const isElementDOMNode = (element) => {
  if (typeof Node === 'undefined') return false;
  return (
    element &&
    typeof element === 'object' &&
    element.nodeType === Node.ELEMENT_NODE
  );
};

/**
 * This gets or create an DOM element.
 * Uses getElementById to search for the element in the whole DOM and if it exist returns it. If the element doesn't
 * exist, it will be created and added as child of the `parent` argument.
 * @param {string} elementId An string that has the ID of the element to be searched.
 * @param {string} [tagName=div] An string that tells the browser which element tag has to be created.
 * @param {Node} [parent=document.body] A Node element that will be the parent of the element if it needs to be created.
 */
export const getOrCreateElementById = (
  elementId,
  tagName = 'div',
  parent = document.body
) => {
  let element = document.getElementById(elementId);
  if (!element) {
    element = document.createElement(tagName);
    element.id = elementId;

    if (parent && parent.nodeType === Node.ELEMENT_NODE) {
      parent.appendChild(element);
    }
  }
  return element;
};

/**
 * Removes an element from the DOM
 * @param {Node|string} element The element that will be removed from the DOM. If an string is passed it uses
 * getElementById to search for it in the whole DOM.
 * @param {Nod} [parent=document.body] A Node element that is the parent of the element that will be removed.
 */
export const removeElementFromDOM = (element, parent = document.body) => {
  let elementToRemove = element;
  if (typeof element === 'string') {
    elementToRemove = document.getElementById(element);
  }

  if (!elementToRemove || !isElementDOMNode(parent)) {
    return false;
  }

  parent.removeChild(elementToRemove);
  return true;
};

export function listen(node, eventName, handler, options) {
  node.addEventListener(eventName, handler, options);
  return () => {
    node.removeEventListener(eventName, handler, options);
  };
}

export function ownerDocument(node) {
  return (node && node.ownerDocument) || document;
}

export function isLeftClickEvent(event) {
  return event.button === 0;
}

export function contains(context, node) {
  if (context.contains) return context.contains(node);
  if (context.compareDocumentPosition) {
    // eslint-disable-next-line no-bitwise
    return context === node || !!(context.compareDocumentPosition(node) & 16);
  }
}

export const getHostName = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

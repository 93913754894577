export default function LinearProgress(theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '10px!important',
        },
        bar: {
          backgroundColor: '#136637',
          borderRadius: '10px',
        },
      },
    },
  };
}

export const urlRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

export const singleWordRegex =
  /^[A-Za-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ]+$/;

export const specialCharacter = /[-¡!$%^&*()_+|=§º¿`´¬·ª½\\#{}\[\]:";'<>?,.\/]/;
export const noSpecialCharacters = /^[\p{L}\sáéíóúüÁÉÍÓÚÜ]+$/u;
export const relativePath = /^.*\/\/[^\/]+/;

export const replaceBlankSpaces = (str, character = '') => {
  const formatted = str?.replace(/\s+/g, character);
  return formatted;
};

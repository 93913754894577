import React, { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import PropTypes from 'prop-types';

import { WriterlyIcon } from '../Icon/Icon';

import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

export const WriterlySnackBar = forwardRef(
  ({ type = 'success', ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const handleDismiss = useCallback(() => {
      closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    const customTypes = {
      success: {
        color: theme.palette.primary.main,
        icon: 'Outlined49',
      },
      error: {
        color: theme.palette.various.errorMessages,
        icon: 'Outlined34',
      },
    };

    const renderMessage = (message) => {
      const links = props.messageLinks;
      if (links && Object.keys(links).length) {
        for (const key in links) {
          if (Object.hasOwnProperty.call(links, key)) {
            const url = links[key];
            message = message.replace(key, `<a href="${url}">${key}</a>`);
          }
        }
      }
      return <span dangerouslySetInnerHTML={{ __html: message }} />;
    };

    return (
      <SnackbarContent
        ref={ref}
        className={'w-9/10 md:w-[450px] h-[60px]  rounded-[11px] '}
        style={{ background: theme.palette.background.default }}
        data-testid={'snackbar-customContent'}
      >
        <div
          className={
            'w-full border-solid border-[1.5px] rounded-[11px] shadow-[0px_0px_10.4375px_rgba(187_187_187_0.25)]'
          }
          style={{
            borderColor: customTypes[type].color,
            background: theme.palette.background.default,
          }}
          id={props.id}
        >
          <div className={'flex items-center h-full justify-between p-2.5'}>
            <div className="flex">
              <WriterlyIcon
                iconName={customTypes[type].icon}
                width={'18px'}
                height={'18px'}
                color={theme.palette.background.default}
                svgFill={theme.palette.background.default}
                stroke={customTypes[type].color}
                className="ml-3 mt-1"
              />

              <div>
                <Typography variant="h7" className="ml-3">
                  {renderMessage(props.message)}
                </Typography>
              </div>
            </div>

            <IconButton className="h-5 w-5" onClick={handleDismiss}>
              <WriterlyIcon
                iconName={'Outlined45'}
                width={'20px'}
                height={'20px'}
                color={'white'}
                svgFill={'white'}
                stroke={theme.palette.text.secondary}
              />
            </IconButton>
          </div>
        </div>
      </SnackbarContent>
    );
  }
);

WriterlySnackBar.displayName = 'WriterlySnackBar';
WriterlySnackBar.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  messageLinks: PropTypes.object,
};

import { DARK, LIGHT } from './shadows';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#a3f8c4',
  light: '#6ee19a',
  main: '#2ECB71',
  dark: '#1c9354',
  darker: '#05652d',
};
const SECONDARY = {
  lighter: '#F2F3F5',
  light: '#E2E8F0',
  main: '#C5CBD3',
  dark: '#B0B6BE',
  darker: '#7A818C',
};
const INFO = {
  lighter: '#C4EEF5',
  light: '#4EA6C5',
  main: '#00273F',
  dark: '#00162D',
  darker: '#000B1E',
};
const SUCCESS = {
  lighter: '#CDFCCB',
  light: '#63F179',
  main: '#04D14C',
  dark: '#029653',
  darker: '#00644B',
};
const WARNING = {
  lighter: '#FFFBCC',
  light: '#FFF066',
  main: '#FFE100',
  dark: '#B79D00',
  darker: '#7A6500',
};
const ERROR = {
  lighter: '#FFE6D6',
  light: '#FFA083',
  main: '#FF4032',
  dark: '#B71928',
  darker: '#7A0927',
  fields: '#F10000',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F6F6F6',
  200: '#F2F3F5',
  300: '#E2E8F0',
  400: '#C5CBD3',
  500: '#B0B6BE',
  600: '#7A818C',
  700: '#5D5D5D',
  800: '#161C24',
  900: '#1C1C1C',
  '0_0': alpha('#FFFFFF', 0.5),
  300_5: alpha('#E2E8F0', 0.5),
  400_5: alpha('#C5CBD3', 0.5),
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, '#70AEF4'),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[500],
      disabled: GREY[500],
      neutral: GREY[800],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[100] },
    shadows: LIGHT,
    action: { active: GREY[600], ...COMMON.action },
    cards: {
      shadows: {
        primary: 'transparent',
        secondary: 'transparent',
      },
      border: '#E2E8F0',
    },
    chat: {
      background: { ai: '#DCE9FA', user: '#E2E8F0' },
      textColor: { ai: GREY[800], user: GREY[800] },
      actionColor: GREY[600],
    },
    loader: {
      spinner: {
        border: '#2ecb71',
        shadow: '#2ecb7166',
        shadowContrast: '#fff',
        background: '#ffffffa3',
      },
    },
    various: {
      softIcons: GREY[600],
      divider: '#DFDFDF',
      errorPlaceholder: '#F10000',
      errorMessages: '#F10000',
    },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
      neutral: GREY[800],
    },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    shadows: DARK,
    action: { active: GREY[500], ...COMMON.action },
    cards: {
      shadows: {
        primary: 'transparent',
        secondary: 'transparent',
      },
      border: '#7A818C',
    },
    chat: {
      background: {
        ai: '#DCE9FA',
        user: '#FFFFFF',
      },
      textColor: { ai: GREY[800], user: GREY[800] },
      actionColor: GREY[600],
    },
    loader: {
      spinner: {
        border: '#2ecb71',
        shadow: '#2ecb7166',
        shadowContrast: '#02222c',
        background: '#00000099',
      },
    },
    various: {
      softIcons: '#FFF',
      divider: '#FFF',
      errorPlaceholder: '#C94747',
      errorMessages: '#F10000',
    },
  },
  twilight: {
    ...COMMON,
    text: {
      primary: '#fff',
      secondary: GREY[300],
      disabled: GREY[600],
      neutral: GREY[0],
    },
    background: { paper: GREY[800], default: '#2D1F84', neutral: GREY[500_16] },
    shadows: DARK,
    action: { active: GREY[500], ...COMMON.action },
    cards: {
      shadows: {
        primary: '#8c83c340',
        secondary: '#8c83c340',
      },
      border: '#8C83C3',
    },
    chat: {
      background: {
        ai: '#FFFFFF',
        user: '#644EE5',
      },
      textColor: { ai: GREY[800], user: '#fff' },
      actionColor: '#644EE5',
    },
    loader: {
      spinner: {
        border: '#313197',
        shadow: '#31319766',
        shadowContrast: '#161a4280',
        background: '#8c83c39c',
      },
    },
    various: {
      softIcons: '#FFF',
      divider: '#FFF',
      errorPlaceholder: '#C94747',
      errorMessages: '#C94747',
    },
  },
};

export default palette;

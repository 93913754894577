import { makeRequest } from './client';

const fetchAllDocuments = async () => {
  return await makeRequest('document/');
};

const createDocumentRequest = async (body) => {
  return await makeRequest(`document/`, {
    method: 'POST',
    body,
  });
};

const deleteDocumentRequest = async (id) => {
  return await makeRequest(`document/${id}/`, {
    method: 'DELETE',
  });
};

const updateDocumentRequest = async ({ body, id }) => {
  return await makeRequest(`document/${id}`, {
    method: 'PATCH',
    body,
  });
};

const toggleFavoritesRequest = async ({ id, isFavorite }) => {
  return await makeRequest(`document/${id}`, {
    method: 'PATCH',
    body: { is_favorite: isFavorite },
  });
};

export {
  createDocumentRequest,
  updateDocumentRequest,
  fetchAllDocuments,
  deleteDocumentRequest,
  toggleFavoritesRequest,
};

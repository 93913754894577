import { makeRequest } from './client';

const getAuthUserDetails = async () => {
  return await makeRequest(`user-details/`, {
    method: 'GET',
  });
};

const updateUserRequest = async (body, headers) => {
  return await makeRequest('user/', {
    method: 'PATCH',
    body,
    headers: headers || {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const userReportBug = async (body) => {
  return await makeRequest(`user-report-bug/`, {
    method: 'POST',
    body: body,
  });
};

const updatePaymentStatus = async (sessionId) => {
  return await makeRequest('billing/check-payment', {
    method: 'POST',
    body: { session_id: sessionId },
  });
};

const getUserAvatar = async () => {
  return await makeRequest('user-avatar-decode/', {
    method: 'GET',
  });
};

export {
  getAuthUserDetails,
  updateUserRequest,
  userReportBug,
  getUserAvatar,
  updatePaymentStatus,
};

// @import Dependencies
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @import Mui Components
import { Typography, Button } from '@mui/material';

export const GeneralFallback = ({
  containerClasses,
  textStyles,
  textVariant,
  renderReloadButton,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex justify-center flex-col items-center gap-1 mt-5 ${
        containerClasses ? containerClasses : ''
      }`}
    >
      <Typography
        variant={textVariant || 'b4'}
        className="text-center"
        sx={textStyles}
      >
        {t('errors.render')}
      </Typography>

      {renderReloadButton && (
        <Button
          variant="contained"
          size="large"
          className="w-[300px] !p-0"
          onClick={() => window.location.reload()}
        >
          {t('generalActions.reloadPage')}
        </Button>
      )}
    </div>
  );
};

GeneralFallback.propTypes = {
  containerClasses: PropTypes.string,
  textStyles: PropTypes.string,
  textVariant: PropTypes.string,
  renderReloadButton: PropTypes.bool,
};

// @import Dependencies
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// @import Services
import {
  addCustomPersona,
  addStandardPersona,
  deleteFileRequest,
  deletePersona as deletePersonaAPI,
  editCustomPersona,
  editStandardPersona,
  fetchAllPersonas,
  getFilesByPersonaRequest,
  uploadFileRequest,
} from '../../api/persona';

// @import Reducers
import {
  removePersonaMenu,
  setPersonaMenu,
  updatePersonaMenu,
} from '../../redux/slices/navigation';
import { addPersona } from '../../redux/slices/user';

// @import Utilities
import { nomenclatureSnack } from '../../utils/nomenclature';

const personasAdapter = createEntityAdapter({});

export const initialState = personasAdapter.getInitialState({
  loading: false,
  isFileProcessing: false,
  files: [],
  standingPersona: null,
});

export const uploadFile = createAsyncThunk(
  'personas/uploadFile',
  async ({ formData, personaId }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await uploadFileRequest(formData);
    if (isSuccessful) {
      return {
        personaId,
        file: {
          id: data.id,
          name: formData.get('name'),
          description: data.description,
        },
        statusKey,
      };
    }
    return thunkAPI.rejectWithValue({
      personaId,
      file: {
        name: formData.get('name'),
        description: formData.get('description'),
      },
      statusKey,
    });
  }
);

export const deleteFile = createAsyncThunk(
  'personas/deleteFile',
  async ({ file, personaId }, thunkAPI) => {
    const { isSuccessful, statusKey } = await deleteFileRequest(file?.id);
    if (isSuccessful) {
      return { file: { id: file?.id, name: file?.name }, personaId, statusKey };
    }
    return thunkAPI.rejectWithValue({
      file: file.name,
      statusKey,
    });
  }
);

export const getAllPersonas = createAsyncThunk(
  'personas/getAllPersonas',
  async (_, thunkAPI) => {
    const personas = await fetchAllPersonas();
    thunkAPI.dispatch(setPersonaMenu(personas.data));
    return personas.data;
  }
);

export const createStandardPersona = createAsyncThunk(
  'personas/createStandardPersona',
  async (persona, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await addStandardPersona(persona);

    if (isSuccessful) {
      thunkAPI.dispatch(updatePersonaMenu(data));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateStandardPersona = createAsyncThunk(
  'personas/updateStandardPersona',
  async (persona, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await editStandardPersona(
      persona
    );

    if (isSuccessful) {
      thunkAPI.dispatch(updatePersonaMenu(data));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const createCustomPersona = createAsyncThunk(
  'personas/createCustomPersona',
  async (persona, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await addCustomPersona(persona);

    if (isSuccessful) {
      thunkAPI.dispatch(updatePersonaMenu(data));
      thunkAPI.dispatch(addPersona());
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateCustomPersona = createAsyncThunk(
  'personas/updateCustomPersona',
  async (persona, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await editCustomPersona(persona);

    if (isSuccessful) {
      thunkAPI.dispatch(updatePersonaMenu(data));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const deletePersona = createAsyncThunk(
  'personas/deletePersona',
  async (_data, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await deletePersonaAPI(
      _data.persona.id
    );
    if (isSuccessful) {
      thunkAPI.dispatch(removePersonaMenu(data.persona.id));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getFilesByPersona = createAsyncThunk(
  'persona/getFilesByPersona',
  async (personaId, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getFilesByPersonaRequest(
      personaId
    );
    if (isSuccessful) {
      return { personaId, rawFiles: data };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const personasSlice = createSlice({
  name: 'personas',
  initialState,
  reducers: {
    setPersonaId: (state, action) => {
      state.selectedPersonaId = action.payload;
    },
    setStandingPersona: (state, action) => {
      state.standingPersona = action.payload;
    },
    cleanFiles: (state) => {
      state.files = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all Personas
      .addCase(getAllPersonas.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPersonas.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllPersonas.fulfilled, (state, action) => {
        personasAdapter.upsertMany(state, action.payload);
        state.loading = false;
      })

      // Create Persona Standard
      .addCase(createStandardPersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStandardPersona.rejected, (state, action) => {
        state.loading = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(createStandardPersona.fulfilled, (state, action) => {
        personasAdapter.upsertOne(state, action.payload);
        state.loading = false;
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
          extraParams: {
            persona: action.payload.name,
          },
        });
      })

      // Update Persona Standard
      .addCase(updateStandardPersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStandardPersona.rejected, (state, action) => {
        state.loading = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(updateStandardPersona.fulfilled, (state, action) => {
        personasAdapter.upsertOne(state, action.payload);
        state.loading = false;
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
          extraParams: {
            persona: action.payload.name,
          },
        });
      })

      // Create Persona Custom
      .addCase(createCustomPersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomPersona.rejected, (state, action) => {
        state.loading = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(createCustomPersona.fulfilled, (state, action) => {
        const newPersona = action.payload;
        state.files = [];
        personasAdapter.upsertOne(state, newPersona);
        state.loading = false;
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
          extraParams: {
            persona: action.payload.name,
          },
        });
      })

      // Update Persona Custom
      .addCase(updateCustomPersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomPersona.rejected, (state, action) => {
        state.loading = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(updateCustomPersona.fulfilled, (state, action) => {
        personasAdapter.upsertOne(state, {
          ...action.payload,
          file_training_id_db_list_formatted: [],
        });
        state.loading = false;
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
          extraParams: {
            persona: action.payload.name,
          },
        });
      })

      // Delete Persona
      .addCase(deletePersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePersona.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          const data = action.payload;
          nomenclatureSnack({
            type: 'error',
            message: data?.statusKey,
            extraParams: { persona: data.persona.title },
          });
        }
      })
      .addCase(deletePersona.fulfilled, (state, action) => {
        const data = action.payload;
        personasAdapter.removeOne(state, data.persona.id);
        state.loading = false;
        nomenclatureSnack({
          type: 'success',
          message: data?.statusKey,
          extraParams: {
            persona: data.persona.title,
          },
        });
      })

      // ----------DOCUMENTS--------------
      // Upload File
      .addCase(uploadFile.pending, (state) => {
        state.isFileProcessing = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        const { file, personaId, statusKey } = action.payload;
        const newFile = { ...file, status: 'error_uploading' };
        const updatedPersona = { ...state.entities[personaId] };
        if (personaId && updatedPersona?.file_training_id_db_list_formatted) {
          personasAdapter.upsertOne(state, {
            id: personaId,
            file_training_id_db_list_formatted:
              updatedPersona?.file_training_id_db_list_formatted
                ? [
                    ...updatedPersona?.file_training_id_db_list_formatted,
                    newFile,
                  ]
                    // Handle retry
                    .reduce((acc, file) => {
                      // check if the file has an id property
                      if (file.id) {
                        // check if the acc array already has a file with the same id
                        if (!acc.some((f) => f.id === file.id)) {
                          acc.push(file);
                        }
                      } else {
                        // check if the acc array already has a file with the same name
                        if (!acc.some((f) => f.name === file.name)) {
                          acc.push(file);
                        }
                      }
                      return acc;
                    }, [])
                : [newFile],
          });
        } else {
          state.files.push(newFile);
        }

        nomenclatureSnack({
          type: 'error',
          message: statusKey,
          extraParams: { file: file.name },
        });

        state.isFileProcessing = false;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const { file, personaId, statusKey } = action.payload;
        const newFile = { ...file, status: 'done' };
        if (personaId) {
          const updatedPersona = { ...state.entities[personaId] };

          personasAdapter.upsertOne(state, {
            id: personaId,
            file_training_id_db_list_formatted:
              updatedPersona?.file_training_id_db_list_formatted
                ? [
                    ...updatedPersona?.file_training_id_db_list_formatted,
                    newFile,
                  ]
                : [newFile],
          });
        } else {
          state.files.push(newFile);
        }
        state.isFileProcessing = false;
        nomenclatureSnack({
          type: 'success',
          message: statusKey,
          extraParams: {
            fileDescription: newFile.description,
          },
        });
      })

      // Delete File
      .addCase(deleteFile.pending, (state) => {
        state.isFileProcessing = true;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        const { file, statusKey } = action.payload;
        nomenclatureSnack({
          type: 'error',
          message: statusKey,
          extraParams: { file: file },
        });
        state.isFileProcessing = false;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const { file, personaId, statusKey } = action.payload;
        if (personaId) {
          const updatedPersona = { ...state.entities[personaId] };

          const updatedPersonaFiles =
            updatedPersona.file_training_id_db_list_formatted.filter(
              (fileDB) => fileDB.id !== file.id
            );

          personasAdapter.upsertOne(state, {
            id: personaId,
            file_training_id_db_list_formatted: updatedPersonaFiles,
          });

          nomenclatureSnack({
            type: 'success',
            message: statusKey,
            extraParams: {
              file: file.name,
            },
          });
        } else {
          const stateFiles = [...state.files];
          state.files = stateFiles.filter((item) => item.id !== file.id);
        }
        state.isFileProcessing = false;
      })

      // Get Files by persona
      .addCase(getFilesByPersona.pending, (state) => {
        state.isFileProcessing = true;
      })
      .addCase(getFilesByPersona.rejected, (state, action) => {
        state.isFileProcessing = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(getFilesByPersona.fulfilled, (state, action) => {
        const { rawFiles, personaId } = action.payload;
        const files = rawFiles.map((item) => ({
          id: item.id,
          description: item.description,
          name: item.file,
          status: 'done',
        }));

        personasAdapter.upsertOne(state, {
          id: personaId,
          file_training_id_db_list_formatted: files,
        });
        state.isFileProcessing = false;
      });
  },
});

export const { setPersonaId, setStandingPersona, cleanFiles } =
  personasSlice.actions;

export default personasSlice.reducer;

// ------------------SELECTORS-------------
export const {
  selectAll: selectAllPersonas,
  selectById: selectPersonaById,
  selectIds: selectPersonasIds,
} = personasAdapter.getSelectors((state) => state.personas);

export const selectStandingPersona = (state) => state.personas.standingPersona;

export const selectLoading = (state) => state.personas.loading;
export const selectFileProcessing = (state) => state.personas.isFileProcessing;
export const selectPersonaLoaders = createSelector(
  [selectLoading, selectFileProcessing],
  (loading, isFileProcessing) => ({
    loading,
    isFileProcessing,
  })
);

export const dropdownPersona = createSelector([selectAllPersonas], (personas) =>
  personas.map((persona) => {
    return { label: persona.name, value: persona.id, ellipse: persona.color };
  })
);

export const countCustomPersonas = createSelector(
  [selectAllPersonas],
  (personas) => personas.filter((item) => item.is_custom === true).length
);
export const selectAllFiles = (state) => state.files;

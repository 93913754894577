import button from './Button';
import chip from './Chip';
import menu from './Menu';
import inputBase from './InputBase';
import iconButton from './IconButton';
import dialog from './Dialog';
import slider from './Slider';
import card from './Card';
import linearProgress from './LinearProgress';
import popover from './Popover';

export default function componentsOverrides(theme) {
  // eslint-disable-next-line
  return Object.assign(
    button(theme),
    chip(theme),
    menu(theme),
    inputBase(theme),
    iconButton(theme),
    dialog(theme),
    slider(theme),
    card(theme),
    linearProgress(theme),
    popover(theme)
  );
}

import './App.css';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/app/create');
  }, []);
  return <div className="App">...Redirecting</div>;
}

export default App;

// @import Dependencies
import { lazy } from 'react';

// @import Guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminGuard from '../guards/AdminGuard';

// @import Custom Components
import Loadable from '../shared/Lazy/LazyComponent';

// @import Lazy Components
const App = Loadable(lazy(() => import('../App')));
const ReusableComponents = Loadable(
  lazy(() => import('../pages/reusableComponents/reusableComponent'))
);
const Create = Loadable(lazy(() => import('../pages/create/create')));
const Subcategory = Loadable(lazy(() => import('../pages/create/subcategory')));
const Category = Loadable(lazy(() => import('../pages/create/category')));
const WriterlyDocumentMode = Loadable(
  lazy(() => import('../pages/Document/DocumentMode'))
);
const StandardModel = Loadable(
  lazy(() => import('../pages/persona/standardModel'))
);
const CustomModel = Loadable(
  lazy(() => import('../pages/persona/customModel'))
);
const UpgradePlan = Loadable(
  lazy(() => import('../pages/upgradePlan/upgradePlan'))
);
const Account = Loadable(lazy(() => import('../pages/account/account')));
const Settings = Loadable(
  lazy(() => import('../pages/account/settings/settings'))
);
const TeamMembers = Loadable(
  lazy(() => import('../pages/account/teamMembers/teamMembers'))
);
const Subscription = Loadable(
  lazy(() => import('../pages/account/subscription/subscription'))
);
const CustomTemplate = Loadable(
  lazy(() => import('../pages/create/customTemplate/customTemplate'))
);
const WriterlyError = Loadable(lazy(() => import('../pages/error/error')));
const Platform = Loadable(lazy(() => import('../platform/Platform')));
const WriterlyUserFirstTime = Loadable(
  lazy(() => import('../pages/user/FirstTime'))
);
const WriterlySignUpPlan = Loadable(
  lazy(() => import('../pages/signUpPlan/SignUpPlan'))
);
const WriterlyBillingRedirection = Loadable(
  lazy(() => import('../pages/billingRedirection/BillingRedirection'))
);
const WriterlyFirstTimeQuestionnaire = Loadable(
  lazy(() => import('../pages/user/FirstTimeQuestionnaire'))
);
const WriterlyAuthLogin = Loadable(lazy(() => import('../pages/auth/Login')));
const WriterlyAuthSignUp = Loadable(
  lazy(() => import('../pages/auth/SignUp/SignUp'))
);
const WriterlyTeamMemberSignup = Loadable(
  lazy(() => import('../pages/auth/SignUp/TeamMemberSignUp'))
);
const WriterlyAuthPasswordRecovery = Loadable(
  lazy(() => import('../pages/auth/passwordRecovery'))
);
const WriterlyAuthSetNewPassword = Loadable(
  lazy(() => import('../pages/auth/setNewPassword'))
);
const WriterlyAuthverifyEmail = Loadable(
  lazy(() => import('../pages/auth/VerifyEmail'))
);
const WriterlyDriveTabs = Loadable(
  lazy(() => import('../pages/drive/tabs/Layout'))
);
const WriterlyChat = Loadable(
  lazy(() => import('../pages/chatterly/chatterly'))
);

const renderReusableComponents = process.env.REACT_APP_ENV === 'develop';

export const router = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: (
          <GuestGuard>
            <WriterlyAuthLogin />
          </GuestGuard>
        ),
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <WriterlyAuthLogin />
          </GuestGuard>
        ),
      },
      {
        path: 'sign-up',
        element: (
          <GuestGuard>
            <WriterlyAuthSignUp />
          </GuestGuard>
        ),
      },
      {
        path: 'join-your-team',
        element: (
          <GuestGuard>
            <WriterlyTeamMemberSignup />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: (
          <GuestGuard>
            <WriterlyAuthPasswordRecovery />
          </GuestGuard>
        ),
      },
      {
        path: 'set-new-password/:token',
        element: (
          <GuestGuard>
            <WriterlyAuthSetNewPassword />
          </GuestGuard>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <GuestGuard>
            <WriterlyAuthverifyEmail />
          </GuestGuard>
        ),
        children: [
          {
            path: ':token',
            element: <WriterlyAuthverifyEmail />,
          },
        ],
      },
    ],
    errorElement: <WriterlyError />,
  },
  {
    path: 'signup-plan',
    element: (
      <AuthGuard>
        <WriterlySignUpPlan />
      </AuthGuard>
    ),
  },
  {
    path: 'billing-redirection',
    element: (
      <AuthGuard>
        <WriterlyBillingRedirection />
      </AuthGuard>
    ),
  },
  {
    path: 'first-time-questionnaire',
    element: (
      <AuthGuard>
        <WriterlyFirstTimeQuestionnaire />
      </AuthGuard>
    ),
  },
  {
    path: '/first-time',
    element: (
      <AuthGuard>
        <WriterlyUserFirstTime />
      </AuthGuard>
    ),
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <Platform />
      </AuthGuard>
    ),
    children: [
      {
        path: 'chatterly',
        children: [
          {
            index: true,
            element: <WriterlyChat />,
          },
          {
            path: 'document-mode/:id',
            element: <WriterlyDocumentMode />,
          },
        ],
      },
      {
        path: 'drive',
        children: [
          { index: true, element: <WriterlyDriveTabs /> },
          {
            path: ':tabId',
            element: <WriterlyDriveTabs />,
          },
          {
            path: 'document-mode/:id',
            element: <WriterlyDocumentMode />,
          },
        ],
      },
      {
        path: 'upgrade-plan',
        children: [
          {
            path: 'creators',
            element: <UpgradePlan type="CREATORS" />,
          },
          {
            path: 'business',
            element: <UpgradePlan type="BUSINESS" />,
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'subscription',
            element: (
              <AdminGuard>
                <Subscription />
              </AdminGuard>
            ),
          },
          {
            path: 'team-members',
            element: (
              <AdminGuard>
                <TeamMembers />
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: 'persona',
        children: [
          {
            path: 'standard-persona',
            children: [
              {
                index: true,
                element: <StandardModel />,
              },
              { path: ':id', element: <StandardModel /> },
            ],
          },
          {
            path: 'custom-model-persona',
            children: [
              {
                index: true,
                element: <CustomModel />,
              },
              { path: ':id', element: <CustomModel /> },
            ],
          },
        ],
      },
      {
        path: 'create',
        children: [
          { index: true, element: <Category /> },
          { path: 'custom-template', element: <CustomTemplate /> },
          {
            path: ':category',
            children: [
              { index: true, element: <Subcategory /> },
              {
                path: ':subcategory',
                children: [
                  { index: true, element: <Create /> },
                  {
                    path: 'document-mode/:id',
                    element: <WriterlyDocumentMode />,
                  },
                ],
              },
            ],
          },
        ],
      },
      renderReusableComponents && {
        path: 'test-reusable-components',
        element: <ReusableComponents />,
      },
      {
        path: 'test-error-component-500',
        element: <WriterlyError code={500} />,
      },
      {
        path: 'test-error-component-1020',
        element: <WriterlyError code={1020} />,
      },
      {
        path: 'test-error-component-404',
        element: <WriterlyError code={404} />,
      },
      { index: true, element: <App /> },
    ],
  },
];

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Contained Color Primary
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                boxShadow: theme.palette.shadows[3],
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[0],
                backgroundColor: theme.palette.primary.main,
                opacity: 0.4,
              },
            }),

          // Contained Color Secondary
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: theme.palette.grey[900],
              '&:hover': {
                backgroundColor: theme.palette.grey[900],
                boxShadow: theme.palette.shadows[3],
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[0],
                backgroundColor: theme.palette.grey[900],
                opacity: 0.4,
              },
            }),

          // Outlined
          ...(ownerState.variant === 'outlined' && {
            borderColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
            backgroundColor: 'transparent',

            '&:hover': {
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
              backgroundColor: 'transparent',
              boxShadow: theme.palette.shadows[3],
            },
            '&.Mui-disabled': {
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
              backgroundColor: 'transparent',
              opacity: 0.4,
            },
          }),

          // Text
          ...(ownerState.variant === 'text' && {
            color: theme.palette.grey[400],
            textTransform: 'none',
            ...theme.typography.b1,

            '&:hover': {
              color: theme.palette.grey[900],
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[400],
              opacity: 0.5,
            },
          }),
        }),
        sizeLarge: {
          height: 37,
          paddingLeft: 100,
          paddingRight: 100,
        },
        sizeMedium: {
          height: 37,
          paddingLeft: 40,
          paddingRight: 40,
        },
        sizeSmall: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        startIcon: {
          color: theme.palette[600],

          '&:hover': {
            color: theme.palette.grey[900],
          },
        },
      },
    },
  };
}

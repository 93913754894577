import { string } from 'prop-types';

export const sortFilter = ({
  data = [],
  filterCondition = () => {},
  sortValue = string,
  sortDirection = 'desc',
}) => {
  return data.filter(filterCondition).sort((a, b) => {
    if (a[sortValue] > b[sortValue]) {
      return sortDirection === 'asc' ? 1 : -1;
    } else if (a[sortValue] < b[sortValue]) {
      return sortDirection === 'asc' ? -1 : 1;
    } else {
      return 0;
    }
  });
};

import { makeRequest } from './client';

const getCustomerPortalLink = async (body) => {
  return await makeRequest(`billing/portal-link`, {
    method: 'POST',
    body,
  });
};

export { getCustomerPortalLink };

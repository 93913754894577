import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { guestPaths } from '../routes/paths';
import { useSelector } from 'react-redux';
import { selectUserData } from '../redux/slices/user';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  if (window?.tidioChatApi) window.tidioChatApi?.hide();
  const { user } = useSelector(selectUserData);
  const isAuthenticated =
    localStorage.getItem('accessToken') &&
    localStorage.getItem('emailVerified');
  if (isAuthenticated) {
    const redirectTo =
      user.is_first_time && user.is_account_owner
        ? guestPaths.signUpPlan
        : guestPaths.create;
    return <Navigate to={redirectTo} />;
  }
  return <>{children}</>;
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sidebarData from '../../data/sidebar-sample.json';
import { getCustomerPortalLink } from '../../api/stripe';
import { nomenclatureSnack } from '../../utils/nomenclature';
import { userReportBug } from '../../api/user';

export const initialState = {
  sideBarOpen: true,
  sideBarItemsClicked: [],
  sideBarMenu: sidebarData,
  documentSideBarVisible: false,
  documentSideBarOpen: false,
  pushToDocumentModal: false,
  reportBugModal: false,
  inviteMemberModal: false,
  changePasswordModal: false,
  cancelSubscriptionModal: false,
  requestPricingModal: false,
  mobileMenuOpen: false,
  mobileCreateOutputsOpen: false,
  mobileCreateOutputsTab: 0,
  accessDeniedModal: false,
  accessKey: '',
  loading: false,
};

export const goToStripeCustomerPortal = createAsyncThunk(
  'navigation/goToStripeCustomerPortal',
  async ({ body }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCustomerPortalLink(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ statusKey });
  }
);

export const reportBug = createAsyncThunk(
  'navigation/reportBug',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userReportBug(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    .addCase(goToStripeCustomerPortal.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      window.location.href = action?.payload?.portal_url;
    })
    .addCase(goToStripeCustomerPortal.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })

    // Report Bug
    .addCase(reportBug.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(reportBug.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    .addCase(reportBug.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    });
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sideBarOpen = action.payload;
      if (!state.sideBarOpen) {
        state.sideBarItemsClicked = [];
      }
    },
    toggleSideBarItem: (state, action) => {
      const { id } = action.payload;
      state.sideBarItemsClicked = state.sideBarItemsClicked.find(
        (item) => item === id
      )
        ? [...state.sideBarItemsClicked].filter((item) => item !== id)
        : [...state.sideBarItemsClicked, id];
    },
    toggleDocumentSideBarVisible: (state, action) => {
      state.documentSideBarVisible = action.payload;
    },
    toggleDocumentSideBarOpen: (state, action) => {
      state.documentSideBarOpen = action.payload;
    },
    togglePushToDocumentModal: (state, action) => {
      state.pushToDocumentModal = action.payload;
    },
    setPersonaMenu: (state, action) => {
      const { menuIndex } = menuPersonaIndexes(state);
      if (menuIndex !== undefined) {
        const options = action.payload
          .map((_persona, index) => {
            return menuPersonaOptionObject(_persona, index);
          })
          .concat(
            state.sideBarMenu[menuIndex].children.filter((_m) => _m.showDefault)
          );
        state.sideBarMenu[menuIndex].children = options;
      }
    },
    toggleInviteMemberModal: (state, action) => {
      state.inviteMemberModal = action.payload;
    },
    updatePersonaMenu: (state, action) => {
      const _persona = action.payload;
      const { menuIndex, personaIndex } = menuPersonaIndexes(
        state,
        _persona.id
      );

      if (personaIndex === undefined) {
        const position = state.sideBarMenu[menuIndex].children.length;
        const optionData = menuPersonaOptionObject(_persona, position + 1);
        state.sideBarMenu[menuIndex].children.splice(
          position - 1,
          0,
          optionData
        );
      } else {
        const oldData = state.sideBarMenu[menuIndex].children[personaIndex];
        state.sideBarMenu[menuIndex].children[personaIndex] = {
          ...oldData,
          title: _persona.name,
          color: _persona.color,
        };
      }
    },
    removePersonaMenu: (state, action) => {
      const { menuIndex, personaIndex } = menuPersonaIndexes(
        state,
        action.payload
      );
      if (personaIndex !== undefined) {
        state.sideBarMenu[menuIndex].children.splice(personaIndex, 1);
      }
    },
    toggleReportBugModal: (state, action) => {
      state.reportBugModal = action.payload;
    },
    toggleChangePasswordModal: (state, action) => {
      state.changePasswordModal = action.payload;
    },
    setCancelSubscriptionModal: (state, action) => {
      state.cancelSubscriptionModal = action.payload;
    },
    toggleRequestPricingModal: (state, action) => {
      state.requestPricingModal = action.payload;
    },
    toggleMobileMenuOpen: (state, action) => {
      state.mobileMenuOpen = action.payload;
    },
    toggleMobileCreateOutputsOpen: (state, action) => {
      state.mobileCreateOutputsOpen = action.payload;
    },
    toggleMobileCreateOutputsTab: (state, action) => {
      state.mobileCreateOutputsTab = action.payload;
    },
    toggleAccessDeniedModal: (state, action) => {
      state.accessDeniedModal = action.payload;
    },
    setAccessKey: (state, action) => {
      state.accessKey = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  toggleSideBar,
  toggleSideBarItem,
  toggleDocumentSideBarOpen,
  toggleDocumentSideBarVisible,
  togglePushToDocumentModal,
  toggleInviteMemberModal,
  setPersonaMenu,
  removePersonaMenu,
  updatePersonaMenu,
  toggleReportBugModal,
  toggleChangePasswordModal,
  setCancelSubscriptionModal,
  toggleRequestPricingModal,
  toggleMobileMenuOpen,
  toggleMobileCreateOutputsOpen,
  toggleMobileCreateOutputsTab,
  toggleAccessDeniedModal,
  setAccessKey,
} = navigationSlice.actions;
export default navigationSlice.reducer;

// ------------------SELECTORS-------------

const menuPersonaOptionObject = (_persona, unique) => {
  return {
    id: _persona.id,
    unique: `persona${_persona.id}-${unique}`,
    title: _persona.name,
    color: _persona.color,
    renderPersonaOptions: true,
    children: [],
    target: '_self',
    is_custom: _persona.is_custom,
  };
};
const menuPersonaIndexes = (state, personaId) => {
  const indexes = {
    menuIndex: undefined,
    personaIndex: undefined,
  };
  const menuDataIndex = state.sideBarMenu.findIndex(
    (_m) => _m.unique === 'persona'
  );
  if (menuDataIndex !== -1) {
    indexes.menuIndex = menuDataIndex;
    const personaIndex = state.sideBarMenu[menuDataIndex].children.findIndex(
      (_p) => _p.id === personaId
    );
    if (personaIndex !== -1) indexes.personaIndex = personaIndex;
  }
  return indexes;
};

export const selectNavigation = (state) => state.navigation;
export const selectSideBarOpen = (state) => state.navigation.sideBarOpen;
export const selectDocumentSideBarOpen = (state) =>
  state.navigation.documentSideBarOpen;
export const selectPushToDocumentModal = (state) =>
  state.navigation.pushToDocumentModal;
export const selectReportBugModal = (state) => state.navigation.reportBugModal;
export const selectInviteMemberModal = (state) =>
  state.navigation.inviteMemberModal;
export const selectChangePasswordModal = (state) =>
  state.navigation.changePasswordModal;
export const selectCancelSubscriptionModal = (state) =>
  state.navigation.cancelSubscriptionModal;
export const selectRequestPricingModal = (state) =>
  state.navigation.requestPricingModal;

export const selectMobileMenuOpen = (state) => state.navigation.mobileMenuOpen;
export const selectMobileCreateOutputsOpen = (state) =>
  state.navigation.mobileCreateOutputsOpen;
export const selectMobileCreateOutputsTab = (state) =>
  state.navigation.mobileCreateOutputsTab;

export const selectAccessDeniedModal = (state) =>
  state.navigation.accessDeniedModal;

export const selectAccessKey = (state) => state.navigation.accessKey;

import { replaceBlankSpaces } from './regex';

export const formatCustomTemplate = (dbTemplate) => {
  if (dbTemplate) {
    const slug = replaceBlankSpaces(dbTemplate.name)?.toLowerCase();
    return {
      slug,
      icon: 'writerlyCreateCustomTemplateIcon',
      questions: dbTemplate.input_fields,
      ...dbTemplate,
    };
  }
};

// @import Dependencies
import * as React from 'react';
import PropTypes from 'prop-types';
// @import Css

// @import Components
import { svgList } from './svgs';

export const WriterlyIcon = ({ iconName, ...props }) => {
  const svgs = svgList(props);
  const svgRender = svgs[iconName];
  if (!svgRender) return null;
  return (
    <svg
      onClick={props.onClick}
      data-tip={props.dataTip}
      viewBox={svgRender ? svgRender.viewBox : ''}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: `${props.color}`,
        stroke: `${props.stroke}`,
        width: props.width ? `${props.width}` : '20px',
        height: props.height ? `${props.height}` : '20px',
      }}
      fill={props.svgFill ? props.svgFill : 'none'}
      fillOpacity={props.svgOpacity}
      data-testid={props.dataTestId}
    >
      {svgRender.path}
    </svg>
  );
};

WriterlyIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  dataTip: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  stroke: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  svgFill: PropTypes.string,
  svgOpacity: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  onlyCount: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

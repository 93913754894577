// @import Dependencies
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// @import Services
import {
  getAuthUserDetails as getUserDetailsAPI,
  getUserAvatar,
  updatePaymentStatus as updateAuthStatusAPI,
  updateUserRequest as updateAuthUserAPI,
} from '../../api/user';
import { getTeamMembersRequest } from '../../api/teamMember';

// @import Reducers
import { setLoginLoading, setVerifyEmail } from '../../redux/slices/auth';

// @import Utilities
import { nomenclatureSnack } from '../../utils/nomenclature';
import { PERMISSIONS } from '../../guards/permissionMap';

export const initialState = {
  data: {
    id: null,
    uuid: null,
    first_name: null,
    last_name: null,
    email: null,
    avatar: '',
    language: 'EN',
    is_email_verified: false,
    ui_mode:
      (typeof localStorage !== 'undefined' && localStorage.getItem('theme')) ||
      'light',
    is_first_time: true,
    current_project: null,
    total_credit_usage: 0,
    total_credit_expenditures: 0,
    total_credit_boosts: 0,
    is_beta: false,
    is_free_plan: false,
    free_trial_test: false,
    is_active: false,
    team_members: null,
    is_account_owner: false,
    seat_limits: null,
    custom_template_limits: null,
    custom_template_used: null,
  },
  accessToken: null,
  loading: false,
  uploadingError: false,
  isUploaded: false,
  isUploading: false,
  paymentStatus: null,
  permissions: {
    storageLimit: 0,
    hasChat: false,
    hasPersonas: false,
    hasCustomPersonas: false,
    hasCustomTemplates: false,
    hasShare: false,
  },
};

export const uploadAvatar = createAsyncThunk(
  'user/uploadAvatar',
  async ({ avatarFormData, base64Img }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateAuthUserAPI(
      avatarFormData
    );
    if (isSuccessful) {
      return {
        avatar: base64Img,
      };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateAuthUserAPI(user);
    if (isSuccessful) {
      const { data: avatarData } = await getUserAvatar();
      data['avatar'] = avatarData.avatar_decoded;
      return { data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getAuthUserDetails = createAsyncThunk(
  'user/getAuthUserDetails',
  async (userData, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await getUserDetailsAPI();

    if (isSuccessful) {
      if (!data.is_email_verified) {
        thunkAPI.dispatch(
          setVerifyEmail({ sentSinceRegister: true, email: data.email })
        );
        return thunkAPI.rejectWithValue({ statusKey });
      }
      localStorage.setItem('emailVerified', data.is_email_verified);
      if (data.avatar) {
        const { data: avatarData } = await getUserAvatar();
        data['avatar'] = avatarData.avatar_decoded;
      }
      thunkAPI.dispatch(setLoginLoading(false));
      return {
        data,
        token: userData?.token,
      };
    } else return thunkAPI.rejectWithValue({ statusKey });
  }
);

export const updateUserFirstTime = createAsyncThunk(
  'user/updateUserFirstTime',
  async (_, thunkAPI) => {
    const { statusKey, data, isSuccessful } = await updateAuthUserAPI(
      {
        is_first_time: false,
      },
      { 'Content-Type': 'application/json' }
    );
    if (isSuccessful) return data;
    else return thunkAPI.rejectWithValue({ statusKey });
  }
);

export const updateTheme = createAsyncThunk(
  'user/updateTheme',
  async (theme, thunkAPI) => {
    try {
      const {
        data: userData,
        isSuccessful,
        statusKey,
      } = await updateAuthUserAPI(theme.theme, theme.headers);
      if (isSuccessful) return userData?.ui_mode;
      return thunkAPI.rejectWithValue({ statusKey });
    } catch (error) {
      return thunkAPI.rejectWithValue({ statusKey: 'PATCH_user/.500000' });
    }
  }
);

export const getTeamMembers = createAsyncThunk(
  'user/getTeamMembers',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getTeamMembersRequest();
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ statusKey });
  }
);

export const updatePaymentStatus = createAsyncThunk(
  'user/updatePaymentStatus',
  async (sessionId, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateAuthStatusAPI(
      sessionId
    );
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ statusKey });
  }
);

export const setUserPreferences = createAsyncThunk(
  'user/setUserPreferences',
  async (values) => {
    const response = await updateAuthUserAPI(
      {
        job_type: values.jobType,
        business_size: values.businessSize,
        writerly_purpose: values.writerlyPurpose,
      },
      { 'Content-Type': 'application/json' }
    );
    return response;
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    .addCase(uploadAvatar.pending, (state) => {
      state.isUploading = true;
      state.uploadingError = false;
      state.isUploaded = false;
    })
    .addCase(uploadAvatar.fulfilled, (state, action) => {
      const { avatar } = action.payload;
      state.data.avatar = avatar;
      state.isUploading = false;
      state.uploadingError = false;
      state.isUploaded = false;
    })
    .addCase(uploadAvatar.rejected, (state, action) => {
      state.isUploading = false;
      state.uploadingError = true;
      state.isUploaded = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateTheme.pending, (state, action) => {
      state.data.ui_mode = action.meta.arg?.theme?.ui_mode;
      state.loading = true;
    })
    .addCase(updateTheme.fulfilled, (state, action) => {
      state.data.ui_mode = action.payload;
      state.loading = false;
    })
    .addCase(updateTheme.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updateUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.data = { ...state.data, ...action.payload?.data };
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getAuthUserDetails.pending, (state) => {
      state.loading = true;
    })
    .addCase(getAuthUserDetails.fulfilled, (state, action) => {
      const data = action.payload?.data;
      state.accessToken = action.payload?.token;
      state.permissions = PERMISSIONS[data.plan_name] || state.permissions;
      state.data = {
        ...state.data,
        ...data,
        custom_template_limits: state.permissions.hasCustomTemplates
          ? data?.custom_template_limits
          : 0,
      };
      state.loading = false;
    })
    .addCase(getAuthUserDetails.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updateUserFirstTime.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateUserFirstTime.fulfilled, (state) => {
      state.data.is_first_time = false;
      state.loading = false;
    })
    .addCase(updateUserFirstTime.rejected, (state) => {
      state.loading = false;
    })
    // Get Team Members
    .addCase(getTeamMembers.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTeamMembers.fulfilled, (state, action) => {
      const teamMembers = action.payload;
      if (teamMembers) {
        const admin = teamMembers.find((member) => member.is_account_owner);
        const sortedTeamMembers = teamMembers.filter(
          (member) => member !== admin
        );
        sortedTeamMembers.unshift(admin);
        state.data.team_members = sortedTeamMembers;
      }
      state.loading = false;
    })
    .addCase(getTeamMembers.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updatePaymentStatus.rejected, (state, action) => {
      state.loadingPayment = false;
      state.paymentStatus = action.payload;
    })
    .addCase(updatePaymentStatus.pending, (state) => {
      state.loadingPayment = true;
    })
    .addCase(updatePaymentStatus.fulfilled, (state, action) => {
      state.loadingPayment = false;
      state.paymentStatus = action.payload?.payment_status;
    })
    .addCase(setUserPreferences.rejected, (state) => {
      state.loading = false;
    })
    .addCase(setUserPreferences.pending, (state) => {
      state.loading = true;
    })
    .addCase(setUserPreferences.fulfilled, (state) => {
      state.loading = false;
    });
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setUploadFalse: (state) => {
      state.isUploaded = false;
    },
    setAvatarBaseUrl: (state, action) => {
      const baseUrl = action.payload;
      const previewUrl = baseUrl.meta.previewUrl;
      state.data.avatar = previewUrl;
      state.isUploaded = true;
    },
    removeTeamMemberFromState: (state, action) => {
      const { email } = action.payload;
      const formattedTeamMembers = [...state.data.team_members].map((item) => {
        if (item.email === email) {
          return { ...item, team_member_status_active: false };
        }
        return { ...item };
      });
      state.data.team_members = formattedTeamMembers;
    },
    addWords: (state, action) => {
      state.data.word_used += action.payload;
    },
    addPersona: (state) => {
      state.data.persona_used += 1;
    },
    addCustomTemplate: (state) => {
      state.data.custom_template_used += 1;
    },
    updateTeamMemberRole: (state, action) => {
      const updatedTeamMember = action.payload;
      const teamMemberToChangeIndex = state.data.team_members?.findIndex(
        (teamMember) => teamMember.id === updatedTeamMember.id
      );
      if (teamMemberToChangeIndex !== -1) {
        state.data.team_members[teamMemberToChangeIndex] = updatedTeamMember;
      }
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setUploadFalse,
  setAvatarBaseUrl,
  removeTeamMemberFromState,
  addWords,
  addCustomTemplate,
  addPersona,
  updateTeamMemberRole,
} = userSlice.actions;

export default userSlice.reducer;

// ------------------SELECTORS-------------
const selectUser = (state) => state.user.data;
const selectLoading = (state) => state.user.loading;
const selectIsUploading = (state) => state.user.isUploading;
const selectUploadingError = (state) => state.user.uploadingError;
const selectIsUploaded = (state) => state.user.isUploaded;
const selectPaymentStatus = (state) => state.user.paymentStatus;
const selectPermissions = (state) => state.user.permissions;

export const selectUserData = createSelector(
  [
    selectUser,
    selectLoading,
    selectUploadingError,
    selectIsUploaded,
    selectIsUploading,
    selectPaymentStatus,
    selectPermissions,
  ],
  (
    user,
    loading,
    uploadingError,
    isUploaded,
    isUploading,
    paymentStatus,
    permissions
  ) => ({
    user,
    loading,
    uploadingError,
    isUploaded,
    isUploading,
    paymentStatus,
    permissions,
  })
);

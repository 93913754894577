const sharedCodes = {
  400000: 'Some field is missing or wrong',
  401000:
    'Authentication credentials were not provided. Please provide valid credentials and try again',
  403000:
    'You are not authorized to access this resource. Please contact the administrator',
  404000:
    'The requested resource could not be found. Please check your URL and try again.',
  429000: 'Too many requests',
  500000:
    'An unexpected error occurred. Please try again later or contact the administrator',
  503000:
    'Sorry, we are experiencing technical difficulties with a third-party integration at this time',
  504000:
    'The request timed out. Please try again later or contact the administrator',
};

const messages = {
  default:
    'Something went wrong, please try again or contact the administrator',
  // Auth
  'POST_auth/change-password': {
    ...sharedCodes,
    201: 'The new password was successfully saved',
    400003: 'Old password wrong',
    500000:
      'Oops, something went wrong while changing your password, please try again',
  },
  'GET_auth/email-verification': {
    ...sharedCodes,
    400007: 'Email already verified',
  },
  'POST_auth/forgot-password': {
    ...sharedCodes,
    400004: 'Invalid Email',
    503002: 'Email service unavailable',
    404001: 'User not found, please check and try again',
  },
  'POST_auth/google': {
    ...sharedCodes,
    401001: 'Something wrong with google authentication',
    500002: 'Something wrong with subscribe (by nickname)',
    503001: 'Third-Party Service Unavailable.',
  },
  'POST_auth/login': {
    ...sharedCodes,
    400006: 'Credentials were not provided.',
    401004: 'Incorrect authentication credentials.',
  },
  'POST_auth/logout': {
    ...sharedCodes,
    400001: 'Invalid token',
  },
  'POST_auth/register': {
    ...sharedCodes,
    500001: 'An account with this email already exists',
  },
  'POST_auth/reset-password/{token}/': {
    ...sharedCodes,
    400001: 'Invalid token',
    400005: 'The same password was used',
    400006: 'Credentials were not provided.',
    401002: 'The reset password link has expired',
  },
  'GET_auth/verify-email/{token}/': {
    ...sharedCodes,
    200: 'The email {{email}} has been confirmed successfully!',
    400001: 'Invalid token',
    400007: 'Email already verified',
    401003: 'The email verification link has expired',
    500002: 'Something wrong with subscribe (by nickname)',
  },
  // Chat
  'POST_writerly-chat/': {
    ...sharedCodes,
    403003: 'Word limit exceeded',
    503000: 'Service Unavailable.',
  },
  'GET_writerly-chat/{id}': {
    ...sharedCodes,
    403003: 'Word limit exceeded',
    503000: 'Service Unavailable.',
  },
  // Create
  'POST_writerly/': {
    ...sharedCodes,
    403003: 'Word limit exceeded',
    404002: 'Private prompt',
    404003: 'Not authorized to use this prompt',
  },
  'GET_writerly/{id}': {
    ...sharedCodes,
    403003: 'Word limit exceeded',
    404002: 'Private prompt',
    404003: 'Not authorized to use this prompt',
  },
  'GET_prompt_templates/{id}/history/': {
    ...sharedCodes,
    200: 'Successfully retrieved {{subcategory}} history',
    500000: 'Oops, something went wrong while getting your creation history',
  },
  'POST_prompt-template/custom-template/': {
    ...sharedCodes,
    200: '{{template}} Created Successfully',
    400015: 'No prompt generated',
    400016: 'Some of the columns do not match the required inputs',
    403001: 'Has not paid plan',
    403007: 'Custom Template limit exceeded',
    500000: 'Oops, something went wrong creating your custom template',
  },
  'GET_prompt-template/custom-template/': { ...sharedCodes },
  'GET_prompt_templates/': { ...sharedCodes },
  // CSV
  'POST_csv-batch-upload/': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
    403006: 'This order exceeds the row limits',
  },
  'POST_csvfile/': {
    ...sharedCodes,
    500000: 'Something went wrong uploading the file, please try again',
  },
  'DELETE_csvfile/{id}/': {
    ...sharedCodes,
    500000: 'Something went wrong deleting the file, please try again',
  },
  // Drive
  'GET_document/': { ...sharedCodes },
  'POST_document/': {
    ...sharedCodes,
    201: 'Document created successfully',
    500000:
      'Oops, something went wrong while creating your document, please try again',
  },
  'GET_document/{id}/': {
    ...sharedCodes,
    400017: 'Invalid Document Id',
  },
  'PATCH_document/{id}': {
    ...sharedCodes,
    200: 'Document Updated Successfully',
    500000:
      'Oops, something went wrong while updating your document, please try again',
  },
  'DELETE_document/{id}/': {
    ...sharedCodes,
    204: 'Document deleted successfully',
    400017: 'Invalid Document Id',
    500000:
      'Oops, something went wrong while deleting your document, please try again',
  },
  // Persona
  'GET_persona/': { ...sharedCodes },
  'POST_persona-standard/': {
    ...sharedCodes,
    201: 'The {{persona}} persona was successfully saved',
    500000:
      'Oops, something went wrong while creating your persona, please try again',
  },
  'PATCH_persona-standard/{id}/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully updated',
    500000:
      'Oops, something went wrong while updating your persona, please try again',
  },
  'POST_persona-custom/': {
    ...sharedCodes,
    201: 'The {{persona}} persona was successfully saved',
    403004: 'Persona limit exceeded',
    503001: 'Third-Party Service Unavailable.',
    500000:
      'Oops, something went wrong while creating your persona, please try again',
  },
  'PATCH_persona-custom/{id}/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully updated',
    500000:
      'Oops, something went wrong while updating your persona, please try again',
  },
  'DELETE_persona/{id}/': {
    ...sharedCodes,
    204: "The '{{persona}}' persona was successfully deleted",
    500000:
      'Oops, something went wrong while deleting your persona, please try again',
  },
  'POST_persona-filetraining/': {
    ...sharedCodes,
    200: '{{fileDescription}} uploaded successfully',
    413000: 'Content Too Large.',
    415000: 'The file type is not supported.',
    500004: 'Internal Server Error occured during the file upload process.',
    500000: "Error uploading '{{file}}",
  },
  'DELETE_persona-filetraining/{id}/': {
    ...sharedCodes,
    204: "The '{{file}}' was deleted successfully",
    500000: "Error deleting '{{file}}",
  },
  'GET_persona-filetraining-by-persona/{id}/': { ...sharedCodes },
  // Stripe
  'POST_billing/portal-link': {
    ...sharedCodes,
    200: 'Redirecting to customer portal...',
    500003: 'Something wrong with the billing portal',
    500000: 'Error redirecting to customer portal',
  },
  // Team Member
  'GET_team_member/members': {
    ...sharedCodes,
    401005: 'Must be owner',
  },
  'POST_team_member/register': {
    ...sharedCodes,
    201: 'Account Created successfully',
    400009: 'All seat used',
    500000:
      'Oops, something went wrong while creating your account, please try again',
  },
  'POST_team_member/add': {
    ...sharedCodes,
    200: 'User invited succesfully!',
    400007: 'Email already verified',
    401006: 'Must be admin',
    500000:
      'An error occurred, make sure of type a valid email or try again later',
  },
  'PATCH_team_member/modify/{id}/': {
    ...sharedCodes,
    404001: 'User not found',
  },
  'POST_team_member/remove': {
    ...sharedCodes,
    204: 'Team Member Successfully Deleted',
    400008: 'Owner cannot be removed',
    500000: 'Error Deleting Team Member',
  },
  // User
  'GET_user-details/': {
    ...sharedCodes,
    404001: 'User not found',
  },
  'PATCH_user/': {
    ...sharedCodes,
    200: 'User updated successfully',
    404001: 'User not found',
    500000: 'Error updating your account',
  },
  'POST_user-report-bug/': {
    ...sharedCodes,
    200: 'The bug was successfully reported!',
    500000:
      'Oops, something went wrong while reporting the bug, please try again',
  },
  'POST_billing/check-payment': { ...sharedCodes },
  'GET_user-avatar-decode/': { ...sharedCodes },
};

export default messages;

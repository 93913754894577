export const hexToRgbaWithOpacity = (hex, opacity) => {
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (h) {
        return h + h;
      })
      .join('');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
};

export const createFormData = (data) => {
  const values = { ...data };
  if (!values.tp_tone) values.tp_tone = 'Excited';
  if (values.template_id === '953c21d5-6f60-4b36-9982-a7cae740fdb8') {
    values['{{ tp_type_of_answer }}'] = values.tp_type_of_answer;
    values['{{ tp_tone }}'] = values.tp_tone;

    delete values.tp_type_of_answer;
    delete values.tp_tone;
  }
  const formData = new FormData();
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      formData.append(key, values[key]);
    }
  }
  return formData;
};

export const deleteNullValues = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
};

export const dataURLToBlob = (dataUrl) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const checkPlanType = (planName, data) => {
  const foundMonthly = data.find((item) => item?.nickname === planName);
  const foundAnnually = data.find((item) => item?.annual_nickname === planName);

  if (foundMonthly) {
    return 'monthly';
  }

  if (foundAnnually) {
    return 'annual';
  }

  return 'unknown';
};

export const mapCategoryTemplates = (categories, templates) => {
  const result = {};
  for (const category in categories) {
    if (category) {
      result[category] = categories[category].map((templateId) => {
        const template = templates.find((t) => t.template_id === templateId);
        return template ? template : null;
      });
    }
  }
  return result;
};

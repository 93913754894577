function path(root, sublink) {
  return `${root}${sublink}`;
}

const root = '/app';
const authRoot = '/';

export const guestPaths = {
  root,
  signUpPlan: '/signup-plan',
  firstTime: '/first-time',
  create: path(root, '/create'),
  chatterly: path(root, '/chatterly'),
  persona: {
    root: path(root, '/persona'),
    standardModel: path(root, '/persona/standard-persona'),
  },
  page404: path(root, '/test-error-component-404'),
  page500: path(root, '/test-error-component-500'),
  page1020: path(root, '/test-error-component-1020'),
  reusableComponents: path(root, '/test-reusable-components'),
  verifyEmail: path(authRoot, 'verify-email'),
};

// TODO: Implement auth paths => WRI-157
export const authPaths = {
  root: authRoot,
  upgradePlan: path(root, '/upgrade-plan'),
};

export const nestedPaths = {
  upgradePlanCreator: path(authPaths.upgradePlan, '/creators'),
  upgradePlanBusiness: path(authPaths.upgradePlan, '/business'),
};

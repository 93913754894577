const routes = {
  // Auth
  'POST_auth/login': /^POST_auth\/login$/,
  'POST_auth/logout': /^POST_auth\/logout$/,
  'POST_auth/register': /^POST_auth\/register$/,
  'GET_auth/email-verification': /^GET_auth\/email-verification$/,
  'GET_auth/verify-email/{token}/':
    /^GET_auth\/verify-email\/[a-zA-Z0-9-_]+\/$/,
  'POST_auth/forgot-password': /^POST_auth\/forgot-password$/,
  'POST_auth/reset-password/{token}/':
    /^POST_auth\/reset-password\/[a-zA-Z0-9-_]+\/$/,
  'POST_auth/change-password': /^POST_auth\/change-password$/,
  'POST_auth/google': /^POST_auth\/google$/,
  // Chat
  'POST_writerly-chat/': /^POST_writerly-chat\/$/,
  'GET_writerly-chat/{id}': /^POST_writerly-chat\/d+\/$/,
  // Create
  'POST_writerly/': /^POST_writerly\/$/,
  'GET_writerly/{id}': /^POST_writerly\/d+\/$/,
  'GET_prompt_templates/{id}/history/':
    /^GET_prompt_templates\/[a-zA-Z0-9-_]+\/history\/(\?page=\d+)?(&page_size=\d+)?$/,
  'POST_prompt-template/custom-template/':
    /^POST_prompt-template\/custom-template\/$/,
  'GET_prompt-template/custom-template/':
    /^GET_prompt-template\/custom-template\/$/,
  'GET_prompt_templates/': /^GET_prompt_templates\/$/,
  // CSV
  'POST_csv-batch-upload/': /^POST_csv-batch-upload\/$/,
  'POST_csvfile/': /^POST_csvfile\/$/,
  'DELETE_csvfile/{id}/': /^DELETE_csvfile\/\d+\/$/,
  // Drive
  'GET_document/': /^GET_document\/$/,
  'POST_document/': /^POST_document\/$/,
  'GET_document/{id}/': /^GET_document\/[a-zA-Z0-9-_]+\/$/,
  'PATCH_document/{id}': /^PATCH_document\/[a-zA-Z0-9-_]+$/,
  'DELETE_document/{id}/': /^DELETE_document\/[a-zA-Z0-9-_]+\/$/,
  // Persona
  'GET_persona/': /^GET_persona\/$/,
  'POST_persona-standard/': /^POST_persona-standard\/$/,
  'PATCH_persona-standard/{id}/': /^PATCH_persona-standard\/\d+\/$/,
  'POST_persona-custom/': /^POST_persona-custom\/$/,
  'PATCH_persona-custom/{id}/': /^PATCH_persona-custom\/\d+\/$/,
  'DELETE_persona/{id}/': /^DELETE_persona\/\d+\/$/,
  'POST_persona-filetraining/': /^POST_persona-filetraining\/$/,
  'DELETE_persona-filetraining/{id}/': /^DELETE_persona-filetraining\/\d+\/$/,
  'GET_persona-filetraining-by-persona/{id}/':
    /^GET_persona-filetraining-by-persona\/\d+\/$/,
  // Stripe
  'POST_billing/portal-link': /^POST_billing\/portal-link$/,
  // Team Member
  'GET_team_member/members': /^GET_team_member\/members$/,
  'POST_team_member/register': /^POST_team_member\/register$/,
  'POST_team_member/add': /^POST_team_member\/add$/,
  'PATCH_team_member/modify/{id}/': /^PATCH_team_member\/modify\/\d+\/$/,
  'POST_team_member/remove': /^POST_team_member\/remove$/,
  // User
  'GET_user-details/': /^GET_user-details\/$/,
  'PATCH_user/': /^PATCH_user\/$/,
  'POST_user-report-bug/': /^POST_user-report-bug\/$/,
  'POST_billing/check-payment': /^POST_billing\/check-payment$/,
  'GET_user-avatar-decode/': /^GET_user-avatar-decode\/$/,
};

const searchKey = (requestedRoute) => {
  let keys = 'default';
  try {
    for (const key in routes) {
      if (Object.hasOwnProperty.call(routes, key)) {
        const element = routes[key];
        if (element.test(requestedRoute)) {
          keys = key;
          break;
        }
      }
    }
    return keys;
  } catch (error) {
    return keys;
  }
};

export default searchKey;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import i18next from 'i18next';

// @import API
import { getChatWritten, writeChat } from '../../api/chat';

// @import Utils
import { addWords } from './user';
import { nomenclatureSnack } from '../../utils/nomenclature';
import { sortFilter } from '../../utils/sortFilter';
import { serializedMessages } from '../../utils/chatgpt';
import {
  ASSISTANCE_NAME,
  DEFAULT_SETTINGS,
  WELCOME_MESSAGE,
} from '../../pages/chatterly/chatDefault';
import getRandomOption from '../../utils/getRandomOption';
import { pollRequest } from '../../utils/slices';
import { isFreePlan } from '../../utils/plan-codes';

const chatAdapter = createEntityAdapter({});

const initialState = chatAdapter.getInitialState({
  ids: [0],
  entities: [
    {
      id: 0,
      role: ASSISTANCE_NAME,
      content: WELCOME_MESSAGE,
      icons: false,
      actions: null,
    },
  ],
  settings: DEFAULT_SETTINGS,
  clipboard: '',
  loading: false,
});

export const write = createAsyncThunk(
  'chatterly/write',
  async (body, thunkAPI) => {
    let firstTime = false;
    const intervalId = setInterval(() => {
      if (!firstTime) {
        thunkAPI.dispatch(
          sendMessage({
            role: ASSISTANCE_NAME,
            content: getRandomOption(),
            isWaitingMessage: true,
          })
        );
        firstTime = true;
      } else {
        thunkAPI.dispatch(
          updateMessage({
            content: getRandomOption(),
          })
        );
      }
    }, 10000);

    const { data, isSuccessful, statusKey } = await writeChat(body);
    if (!isSuccessful) {
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const state = thunkAPI.getState();
    const requestSeconds = isFreePlan(state?.user?.data?.plan_name) ? 1 : 3;

    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data.id },
      request: getChatWritten,
      seconds: requestSeconds,
    });
    if (!isPollSuccessful) {
      return thunkAPI.rejectWithValue({
        ...pollData,
        statusKey: pollStatusKey,
      });
    }
    clearInterval(intervalId);
    const words = pollData.messages[0].content.split(' ').length;
    thunkAPI.dispatch(addWords(words));
    return pollData;
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder

    // Write
    .addCase(write.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(write.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    .addCase(write.fulfilled, (state, action) => {
      const [ai] = action.payload.messages;
      const isWaitingMessage =
        state.entities[state.ids.length - 1]?.role === 'assistant';

      chatAdapter.upsertOne(state, {
        id: isWaitingMessage ? state.ids.length - 1 : state.ids.length,
        role: ai.role,
        content: ai.content,
        icons: true,
        actions: null,
        isWaitingMessage: false,
      });
      state.loading = false;
    });
};

export const chatterlySlice = createSlice({
  name: 'chatterly',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    sendMessage: (state, action) => {
      chatAdapter.upsertOne(state, {
        id: state.ids.length,
        ...action.payload,
      });
    },
    updateMessage: (state, action) => {
      chatAdapter.upsertOne(state, {
        id: action.payload.id || state.ids.length - 1,
        ...action.payload,
      });
    },
    setInputLanguage: (state, action) => {
      state.settings.input_language = action.payload;
    },
    setOutputLanguage: (state, action) => {
      state.settings.output_language = action.payload;
    },
    setPersona: (state, action) => {
      state.settings.persona = action.payload;
    },
    setTone: (state, action) => {
      state.settings.tone = action.payload;
    },
    resetSettings: (state, action) => {
      state.settings = DEFAULT_SETTINGS;
    },
    setClipboard: (state, action) => {
      state.clipboard = action.payload || i18next.t('documentMode.placeholder');
    },
    resetChat: (state, action) => {
      chatAdapter.setAll(state, [
        {
          id: 0,
          role: ASSISTANCE_NAME,
          content: WELCOME_MESSAGE,
        },
      ]);
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  sendMessage,
  updateMessage,
  setInputLanguage,
  setOutputLanguage,
  setPersona,
  setTone,
  resetSettings,
  setClipboard,
  resetChat,
} = chatterlySlice.actions;
export default chatterlySlice.reducer;

// ------------------SELECTORS-------------
export const {
  selectAll: selectAllMessages,
  selectById: selectMessageById,
  selectIds: selectMessagesIds,
} = chatAdapter.getSelectors((state) => state.chatterly);
export const selectInputLanguage = (state) =>
  state.chatterly.settings.input_language;
export const selectOutputLanguage = (state) =>
  state.chatterly.settings.output_language;
export const selectPersona = (state) => state.chatterly.settings.persona;
export const selectTone = (state) => state.chatterly.settings.tone;
export const selectClipboard = (state) => state.chatterly.clipboard;
export const selectSettings = (state) => state.chatterly.settings;
export const selectLoading = (state) => state.chatterly.loading;
export const selectLastIAMessage = createSelector(
  selectAllMessages,
  (messages) =>
    sortFilter({
      data: messages,
      filterCondition: (message) => message.role === ASSISTANCE_NAME,
      sortValue: 'id',
    }).shift()
);

export const createRequestBody = createSelector(
  [
    selectAllMessages,
    selectSettings,
    (state, newMessage) => newMessage.content !== '' && newMessage,
  ],
  (messages, settings, newMessage) => {
    const history = serializedMessages(messages);

    return { messages: [...history, newMessage], ...settings };
  }
);

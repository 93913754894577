import {
  createSelector,
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import {
  createDocumentRequest,
  fetchAllDocuments,
  updateDocumentRequest,
  deleteDocumentRequest,
  toggleFavoritesRequest,
} from '../../api/drive';

// @import Utilities
import { nomenclatureSnack } from '../../utils/nomenclature';
import { snack } from '../../utils/snackbar';

const documentsAdapter = createEntityAdapter({
  selectId: (entity) => entity.uuid,
});

export const initialState = {
  documents: documentsAdapter.getInitialState([]),
  loading: false,
  selectedTab: null,
};

export const getAllDocuments = createAsyncThunk(
  'drive/getAllDocuments',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await fetchAllDocuments();
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const createDocument = createAsyncThunk(
  'drive/createDocument',
  async (data, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await createDocumentRequest(data);
    if (isSuccessful) return { ...res, statusKey };
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const updateDocument = createAsyncThunk(
  'drive/updateDocument',
  async (data, thunkAPI) => {
    const { id, body } = data;
    const {
      isSuccessful,
      statusKey,
      data: res,
    } = await updateDocumentRequest({ id, body });
    if (isSuccessful) return { ...res, statusKey };
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const deleteDocument = createAsyncThunk(
  'drive/deleteDocument',
  async (id, thunkAPI) => {
    const { isSuccessful, statusKey, data } = await deleteDocumentRequest(id);
    if (isSuccessful) return { id, statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const toggleInFavorites = createAsyncThunk(
  'drive/toggleInFavorites',
  async ({ id, isFavorite }, thunkAPI) => {
    const {
      isSuccessful,
      statusKey,
      data: res,
    } = await toggleFavoritesRequest({
      id,
      isFavorite,
    });
    if (isSuccessful) return res;
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder

    // Get all Documents
    .addCase(getAllDocuments.pending, (state) => {
      state.loading = true;
    })
    .addCase(getAllDocuments.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getAllDocuments.fulfilled, (state, action) => {
      documentsAdapter.upsertMany(state.documents, action.payload);
      state.loading = false;
    })

    // Create Document
    .addCase(createDocument.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createDocument.fulfilled, (state, action) => {
      documentsAdapter.upsertOne(state.documents, action.payload);
      state.loading = false;
      // If creating document from /app/create
      if (window.location.pathname === '/app/create') {
        window.location.replace(
          window.location.pathname.replace(
            '/app/create',
            `/app/drive/document-mode/${action.payload.uuid}`
          )
        );
      } else {
        window.history.pushState(
          null,
          null,
          `${window.location.pathname}/document-mode/${action.payload.uuid}`
        );
      }
      window.dispatchEvent(new Event('popstate'));
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(createDocument.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Update Document
    .addCase(updateDocument.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateDocument.fulfilled, (state, action) => {
      documentsAdapter.upsertOne(state.documents, action.payload);
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateDocument.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Update Document
    .addCase(deleteDocument.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteDocument.fulfilled, (state, action) => {
      const { id } = action.payload;
      documentsAdapter.removeOne(state.documents, id);
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(deleteDocument.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Toggle in favorites
    .addCase(toggleInFavorites.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(toggleInFavorites.fulfilled, (state, action) => {
      documentsAdapter.upsertOne(state.documents, action.payload);
      state.loading = false;
      if (action.payload.is_favorite)
        snack('success', 'drive.snacks.favorites.success_added');
      else snack('success', 'drive.snacks.favorites.success_removed');
    })
    .addCase(toggleInFavorites.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const driveSlice = createSlice({
  name: 'drive',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setCurrentTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const { setCurrentTab } = driveSlice.actions;
export default driveSlice.reducer;

export const {
  selectAll: selectAllDocuments,
  selectById: selectDocumentById,
  selectIds: selectDocumentsIds,
} = documentsAdapter.getSelectors((state) => state.drive.documents);

// ------------------SELECTORS-------------
export const selectDrive = (state) => state.drive;
export const selectDocuments = (state) => state.drive.documents?.entities;
export const selectLoading = (state) => state.loading;
export const selectFileById = createSelector(
  [selectDocuments, (state, fileId) => fileId],
  (files, fileId) => {
    return Object.values(files).find((item) => item.uuid === fileId);
  }
);
export const selectFavorites = createSelector(
  [selectDocuments, (state) => state],
  (files) => {
    return Object.values(files).filter((item) => item.is_favorite === true);
  }
);
